
import { useReturnStore } from "@/store/returnStore";
import {
  FamilyReturnItemRecommandations,
  ReturnItemRecommandation,
} from "@/type/Return/ReturnType";
import { computed, defineComponent, ref, toRaw } from "vue";
import InputTextSite from "@/components/customInput/InputTextSite.vue";
import dialog from "@/services/DialogSite";
import { useI18n } from "vue-i18n";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import ServiceTable from "@/services/ServiceTable";
import BinariStatus from "@/components/global/BinariStatus.vue";

export default defineComponent({
  name: "TableReturnItem",
  emits: [
    "updateItemRecommandation",
    "openModalStatsDetailReturnItem",
    "openModalReturnItemByFamily",
  ],
  props: {
    familyReturnItemRecommandations: {
      type: Object as () => FamilyReturnItemRecommandations,
      required: true,
    },
  },
  components: {
    InputTextSite,
    ButtonSite,
    BinariStatus,
  },
  setup(props) {
    const { t } = useI18n();
    const returnStore = useReturnStore();
    const getReturnPreco = computed(() => {
      return returnStore.getReturnPreco;
    });

    const getReturnData = computed(() => {
      return returnStore.getReturnData;
    });

    //creation d'un objet réactif avec le props de FamilyReturnItemRecommandations
    const tempFamilyReturnItemRecommandations = ref(
      toRaw(
        props.familyReturnItemRecommandations
      ) as FamilyReturnItemRecommandations
    );

    const rows = ref(
      tempFamilyReturnItemRecommandations.value
        .arrayReturnItemRecommandations as ReturnItemRecommandation[]
    );

    const filter = ref({
      idErpItem: "" as string,
      shortRef: "" as string,
      lineModified: null as null | boolean,
    });

    const columns = [
      {
        name: "detail",
        label: "message.return.select_return.family_preco.table.detail",
        align: "left",
      },
      {
        name: "idErpItem",
        required: true,
        label: "message.return.select_return.family_preco.table.idErpItem",
        align: "left",
        field: (row: ReturnItemRecommandation) => row.idErpItem,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "shortRef",
        required: true,
        label: "message.return.select_return.family_preco.table.shortRef",
        align: "left",
        field: (row: ReturnItemRecommandation) => row.shortRef,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "stock_customer",
        required: true,
        label: "message.return.select_return.family_preco.table.stock_customer",
        align: "center",
        field: (row: ReturnItemRecommandation) => row.qtyStockCustomer,
        sortable: true,
      },
      {
        name: "stock_reserved",
        required: true,
        label: "message.return.select_return.family_preco.table.stock_reserved",
        align: "center",
        field: (row: ReturnItemRecommandation) => row.qtyStockReservedCustomer,
        sortable: true,
      },
      {
        name: "stock_type",
        required: true,
        label: "message.return.select_return.family_preco.table.stock_type",
        align: "center",
        field: (row: ReturnItemRecommandation) => row.qtyStockType,
        sortable: true,
      },
      {
        name: "purchase",
        required: true,
        label: "message.return.select_return.family_preco.table.purchase",
        align: "center",
        field: (row: ReturnItemRecommandation) =>
          row.historicalQuantityBuyToCap,
        sortable: true,
      },
      {
        name: "last_purchase_date",
        required: true,
        label:
          "message.return.select_return.family_preco.table.last_purchase_date",
        align: "center",
        field: (row: ReturnItemRecommandation) => row.formatedDateLastBuyToCap,
        sortable: true,
      },
      {
        name: "unit_price",
        required: true,
        label: "message.return.select_return.family_preco.table.unit_price",
        align: "center",
        field: (row: ReturnItemRecommandation) => row.unitPriceHt,
        sortable: true,
      },
      {
        name: "preco",
        required: true,
        label: "message.return.select_return.family_preco.table.preco",
        align: "center",
        field: (row: ReturnItemRecommandation) => row.qtyProposed,
        sortable: true,
      },
      {
        name: "max_available_qty",
        required: true,
        label:
          "message.return.select_return.family_preco.table.max_available_qty",
        align: "center",
        field: (row: ReturnItemRecommandation) => row.qtyPossibleToReturn,
        sortable: true,
      },
      {
        name: "qty_selected",
        required: true,
        label: "message.return.select_return.family_preco.table.qty_selected",
        align: "center",
        field: (row: ReturnItemRecommandation) => row.qtySelected,
        sortable: true,
      },
      {
        name: "amount",
        required: true,
        label: "message.return.select_return.family_preco.table.amount",
        align: "center",
      },
      {
        name: "item_no_returnable",
        required: true,
        label:
          "message.return.select_return.family_preco.table.item_no_returnable",
        align: "center",
      },
      {
        name: "check",
        required: true,
        label: "message.return.select_return.family_preco.table.check",
        align: "center",
      },
    ];

    const modifyNoReturnable = ref([] as boolean[]);

    return {
      columns,
      rows,
      returnStore,
      getReturnPreco,
      getReturnData,
      tempFamilyReturnItemRecommandations,
      filter,
      modifyNoReturnable,
      t,
      ServiceTable,
    };
  },

  methods: {
    updateLineModified(row: ReturnItemRecommandation) {
      row.lineModified = true;
    },
    hiddenLineModified() {
      this.filter.lineModified = false;
    },
    displayAllRow() {
      this.filter.lineModified = null;
    },
    async addQtySelected(row: ReturnItemRecommandation) {
      this.updateLineModified(row);
      if (row.qtySelected < row.qtyPossibleToReturn - row.qtyNoReturnable) {
        if (
          this.returnStore.checkIfReturnAuthorizedAmountIsNotOver(
            (row.qtySelected + 1) * row.unitPriceHt
          )
        ) {
          row.qtySelected++;
          this.update(row);
          if (
            row.qtySelected +
              row.qtyStockReservedCustomer -
              row.qtyStockCustomer >
            0
          ) {
            dialog.alertReturn(
              this.t(
                "message.return.select_return.family_preco.table.alert.selected_reserved"
              )
            );
          }
        } else {
          dialog.alertReturn(
            this.t(
              "message.return.select_return.family_preco.table.alert.overt_amount"
            )
          );
        }
      } else {
        //alerte stock type
        if (row.qtyStockType > 0) {
          dialog.alertReturn(
            this.t(
              "message.return.select_return.family_preco.table.alert.selected_stock_type",
              { count: row.qtyStockType }
            ),
            this.t(
              "message.return.select_return.family_preco.table.alert.title_unhautorized"
            ),
            true
          );
        } else {
          dialog.alertReturn(
            this.t(
              "message.return.select_return.family_preco.table.alert.can_not_return"
            ),
            this.t(
              "message.return.select_return.family_preco.table.alert.title_unhautorized"
            )
          );
        }
      }
    },
    lessQtySelected(row: ReturnItemRecommandation) {
      this.updateLineModified(row);
      if (row.qtySelected > 0) {
        row.qtySelected--;
        this.update(row);
      }
    },
    addQtyNoReturnable(row: ReturnItemRecommandation) {
      this.updateLineModified(row);
      if (row.qtyNoReturnable < row.qtyPossibleToReturn - row.qtySelected) {
        row.qtyNoReturnable++;
        this.update(row);
      } else {
        dialog.alertReturn(
          this.t(
            "message.return.select_return.family_preco.table.alert.can_not_return"
          )
        );
      }
    },
    lessQtyNoReturnable(row: ReturnItemRecommandation) {
      this.updateLineModified(row);
      if (row.qtyNoReturnable > 0) {
        row.qtyNoReturnable--;
        this.update(row);
      }
    },
    async update(row: ReturnItemRecommandation) {
      //fonction pour mettre le parent et le store à jours
      this.$emit("updateItemRecommandation", row);
    },
    openModalStatsDetailReturnItem(idReturnItemRecomandation: number) {
      this.$emit("openModalStatsDetailReturnItem", idReturnItemRecomandation);
    },
    openModalReturnItemByFamily(regroupementFamily: string) {
      this.$emit("openModalReturnItemByFamily", regroupementFamily);
    },
  },
});
