import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }
const _hoisted_4 = { class: "row blockReturn" }
const _hoisted_5 = { class: "col-md-12 text-center" }
const _hoisted_6 = { class: "row blockReturnStatus" }
const _hoisted_7 = { class: "col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderCustomerData = _resolveComponent("HeaderCustomerData")!
  const _component_HeaderStepButton = _resolveComponent("HeaderStepButton")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_BlockMsgError = _resolveComponent("BlockMsgError")!
  const _component_StepLineReturn = _resolveComponent("StepLineReturn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderCustomerData),
    (_ctx.getHeaderStepReturn)
      ? (_openBlock(), _createBlock(_component_HeaderStepButton, {
          key: 0,
          btns: _ctx.getHeaderStepReturn
        }, null, 8, ["btns"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.create_return_order")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ButtonSite, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isFinalStep() ? _ctx.resetReturn() : _ctx.calcReturn())),
          loading: _ctx.calcReturnPending,
          typeButton: _ctx.isFinalStep() ? 'PASTEL_DANGER' : 'ACTION',
          disable: _ctx.disable
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(_ctx.getLabelBtn())), 1)
          ]),
          _: 1
        }, 8, ["loading", "typeButton", "disable"]),
        (_ctx.disable)
          ? (_openBlock(), _createBlock(_component_BlockMsgError, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stepLines, (stepLine) => {
            return (_openBlock(), _createElementBlock("li", {
              key: stepLine.id
            }, [
              _createVNode(_component_StepLineReturn, {
                id: stepLine.id,
                title: stepLine.title,
                desc: stepLine.desc,
                ref_for: true,
                ref: 'stepLine_' + stepLine.id
              }, null, 8, ["id", "title", "desc"]),
              (_ctx.getStatusError(stepLine.id))
                ? (_openBlock(), _createBlock(_component_BlockMsgError, { key: 0 }))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}