import axios from 'axios';
const url = process.env.VUE_APP_URL_API_NEO_GESTION_CAP;
import { getToken } from '@baloise/vue-keycloak'
import { CustomerGroup, ModelCreateOrUpdateCustomerGroup } from '@/type/CustomerGroupType';
import { Customer, ModelSearchCustomer, PaginateCustomers } from '@/type/CustomerType';
import { ListIdClientSso, MenuSSO } from '@/type/MenuSSOType';
import { ImportedUser } from '@/type/UserType';
import { ExcludedGlobal } from '@/type/MyGarage/MGExcludedItemType';
import { MGRefTypeCustomerGroup } from '@/type/MyGarage/MGRefTypeCustomerGroupType';
import { ADDMGRefFamilyItemErp, MGItemTypeErpExcluded } from '@/type/MyGarage/MGItemTypeErpExcluded';
import { MGRefFamilyItemErp } from '@/type/MyGarage/MGRefFamilyItemErp';
import { MGUsers, ModelSearchUser, PaginateMGUsers } from '@/type/MyGarage/MGUsersType';
import { ModelUpateRefAutoCenter, PaginateMGCustomers, RefAutoCenter } from '@/type/MyGarage/MGCustomerType';
import { ErrorGlobal } from '@/type/Error';
import { ModelSearchLogs, PaginateMGLog } from '@/type/MyGarage/MGLogType';
import { ADDMGRefFamilyItem, MGFamilyExcluded } from '@/type/MyGarage/MGFamilyExcluded';
import { MGRefFamilyItem } from '@/type/MyGarage/MGRefFamilyItem';
import { AmazonOrderBatch, ModelSearchAmazonOrderBatch, PaginateAmazonOrderBatch, ModelOrderBatchProcessing, ModelDoubleWebOrderId } from '@/type/Amazon/AmazonOrderBatchType';
import { ErpMaxLenAddress } from '@/type/GlobalErpType';
import { AmazonOrder, ModelImportOrderErp, ModelUpdateOrder, ResponseRemoveOrder } from '@/type/Amazon/AmazonOrderType';
import { AmazonRefTypeExport } from '@/type/Amazon/AmazonRefTypeExport';
import { AmazonStatsType, ModelGenerateAmazonStats } from '@/type/Amazon/AmazonStatsType';
import { RefAmazonShippingStatus } from '@/type/Amazon/RefAmazonShippingStatus';
import { AmamzonUpdateGroupOrderCarrierStatusShips } from '@/type/Amazon/AmazonUpdateStatusShipRemainder';
import { ForceUpdateItem, MGRefItem } from '@/type/MyGarage/MGRefItem';
import { FindItem, ModelCreateReturn, ModelCustomerOrder, ModelCustomerOrderCa, ModelCustomerOrderDetail, ModelCustomerStock, ModelHistoPurchaseStockTypeEtai, ModelResponseStatus, ModelReturnOrderErp, ModelSelectShipping, ModelUpdateReturnItemRecommandation, RefCarrier, ReturnData, ReturnItemError, ReturnItemRecommandation, ReturnPreco, StatsReturnItemRecommandation } from '@/type/Return/ReturnType';

axios.interceptors.request.use(
  async config => {
    const token = await getToken();
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
    return config
  },
  error => {
    Promise.reject(error)
  },
)

function downloadFile(res: any) {
  const contentDisposition = res.headers["content-disposition"];
  const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const match = contentDisposition.match(regex);
  if (match && match[1]) {
    const filename = match[1];
    const FILE = window.URL.createObjectURL(new Blob([res.data]));
    const docUrl = document.createElement('a') as any;
    docUrl.href = FILE;
    docUrl.setAttribute('download', filename);
    document.body.appendChild(docUrl);
    docUrl.click();
  }
}


export default {

  /***************************************** MENU SSO */
  /**
   * Récupere le menu associer au sso
   * @param listIdClientSso 
   * @returns 
   */
  async getMenuSso(listIdClientSso: ListIdClientSso): Promise<MenuSSO> {
    const data = JSON.stringify(listIdClientSso)
    return axios
      .post(url + 'menu_sso/get_menu_sso', data)
      .then(response => response.data)
  },
  /****************************************************/



  /********************************** CUSTOMER GROUP */
  /**
   * Récupérer les enseigne non bloqué pour faire les recherche des client
   * @returns 
   */
  async getCustomerGroupNotBlocked(): Promise<CustomerGroup[]> {
    return axios
      .get(url + 'api/ref_type_cg/find_not_blocked')
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },

  /**
   * Récupération des toute les enseignes
   * @returns 
   */
  async getCustomerGroup(): Promise<CustomerGroup[]> {
    return axios
      .get(url + 'api/ref_type_cg/find_all')
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },

  /**
   * Suppresion d'une enseigne
   * @param idRefTypeCustomerGroup 
   * @returns 
   */
  async deleteCustomerGroup(idRefTypeCustomerGroup: number): Promise<any> {
    return axios
      .delete(url + 'api/ref_type_cg/delete/' + idRefTypeCustomerGroup)
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },

  /**
   * Mise à jours d'une enseigne
   * @param modelCreateOrUpdateCustomerGroup 
   * @returns 
   */
  async createOrUpdateCustomerGroup(modelCreateOrUpdateCustomerGroup: ModelCreateOrUpdateCustomerGroup): Promise<any> {
    const data = JSON.stringify(modelCreateOrUpdateCustomerGroup)
    return axios
      .post(url + 'api/ref_type_cg/update_or_create', data)
      .then(response => response.data)
  },
  /****************************************************/

  /***************************************** CUSTOMER */
  /**
   * Recherche d'un client dans l'erp
   * @param modelSearchCustomer 
   * @returns 
   */
  async findCustomer(modelSearchCustomer: ModelSearchCustomer): Promise<PaginateCustomers | ErrorGlobal> {
    const data = JSON.stringify(modelSearchCustomer)
    return axios
      .post(url + 'api/erp_customer/find_customers', data)
      .then(response => response.data)
  },

  /**
   * Récupération d'un client dans l'erp
   * @param idCustomer 
   * @returns 
   */
  async getCustomer(idCustomer: string): Promise<Customer> {
    return axios
      .get(url + 'api/erp_customer/find_customer/' + idCustomer)
      .then(response => response.data)
  },

  /****************************************************/

  /***************************************** Admin User */
  /**
   * Création des utilisateur dans le SSO via le fichier CSV de création des utilisateurs
   * @param form 
   * @returns 
   */
  async createUsers(form: FormData): Promise<ImportedUser[]> {
    return axios
      .post(url + 'api/admin_user/create_users', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => response.data)
  },
  /****************************************************/

  /***************************************** Admin Erp Max Len Address */
  /**
   * Récupération des longueurs maximal des champs info adresse et utilisateur de l'erp
   * @returns 
   */
  async getErpMaxLenAddress(): Promise<ErpMaxLenAddress[]> {
    return axios
      .get(url + 'api/erp_max_len_address/get')
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },
  /****************************************************/


  /***************************************** AMAZON *******************************************************************/

  /**
 * Lancement du controle final doublon 
 * @param idOrderBatch 
 * @returns 
 */
  async sendFinalCheckImportOrderAmaz(idOrderBatch: number): Promise<any> {
    return axios
      .get(url + 'api/amazon/order_batch/final_check/' + idOrderBatch)
      .then(response => response.data);
  },

  /**
 * OrderBatch Processing 
 * @param modelOrderBatchProcessing 
 * @returns 
 */
  async sendOrderBatchProcessing(modelOrderBatchProcessing: ModelOrderBatchProcessing): Promise<ModelDoubleWebOrderId[] | null> {
    const data = JSON.stringify(modelOrderBatchProcessing)
    return axios
      .post(url + 'api/amazon/order_batch/mercure/processing', data)
      .then(response => response.data)
  },

  /**
   * Import des commande via le fichier CSV d'amazon pour la création d'un order_batch
   * @param form 
   * @returns 
   */
  async importOrderBatch(form: FormData): Promise<AmazonOrderBatch> {
    return axios
      .post(url + 'api/amazon/order_batch/import_order', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => response.data)
  },

  /**
   * Suppression d'un order batch sans aucune commande
   * @param idOrderbatch 
   * @returns 
   */
  async deleteOrderBatch(idOrderbatch: number): Promise<any> {
    return axios
      .delete(url + 'api/amazon/order_batch/delete_order_batch/' + idOrderbatch)
      .then(response => response.data)
  },

  /**
   * Integration d'une commande à l'erp
   * @param modelImportOrderErp 
   * @returns 
   */
  async sendOrderToErp(modelImportOrderErp: ModelImportOrderErp): Promise<AmazonOrder> {
    const data = JSON.stringify(modelImportOrderErp)
    return axios
      .post(url + 'api/amazon/order/send_orders_to_erp', data)
      .then(response => response.data)
  },
  /**
   * Récupération des order batchs paginer en fonction d'une recherche et de trie
   * @param modelSearchAmazonOrderBatch 
   * @returns 
   */
  async findPaginateOrderBatch(modelSearchAmazonOrderBatch: ModelSearchAmazonOrderBatch): Promise<PaginateAmazonOrderBatch | ErrorGlobal> {
    const data = JSON.stringify(modelSearchAmazonOrderBatch)
    return axios
      .post(url + 'api/amazon/order_batch/find_paginate_order_batch', data)
      .then(response => response.data)
  },
  /**
   * Récupération du detail d'un order batch
   * @param idOrderBatch 
   * @returns 
   */
  async getOrderBatch(idOrderBatch: any): Promise<AmazonOrderBatch> {
    return axios
      .get(url + 'api/amazon/order_batch/get_order_batch/' + idOrderBatch)
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },

  /**
   * Suppression d'une commande
   */
  async removeOrderAmaz(idOrder: any): Promise<ResponseRemoveOrder> {
    return axios.delete(url + 'api/amazon/order/remove/' + idOrder)
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },

  /**
     * Récupération Des commandes en reliquats de status Tntn
     * 
     * @returns AmazonOrder[]
     */
  async getOrderRemainderCarrier(): Promise<AmazonOrder[]> {
    return axios
      .get(url + 'api/amazon/order/get_order_remainder_carrier')
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },
  /**
   *  Récupération des status finaaux de livraison Amazon
   * 
   * @returns RefAmazonShippingStatus[]
  */
  async getAmazonShippingFinalStatus(): Promise<RefAmazonShippingStatus[]> {
    return axios
      .get(url + 'api/amazon/ref_shipping_status/get_shipping_final_status')
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },
  async updateOrderRemainderShipStatus(amamzonUpdateGroupOrderCarrierStatusShips: AmamzonUpdateGroupOrderCarrierStatusShips): Promise<AmazonOrder[]> {
    const data = JSON.stringify(amamzonUpdateGroupOrderCarrierStatusShips)
    return axios
      .post(url + 'api/amazon/order/update_order_remainder_carrier', data)
      .then(response => response.data)
  },


  /**
   * Mise à jours d'une commande Amazon
   * @param modelUpdateOrder 
   * @returns 
   */
  async updateOrder(modelUpdateOrder: ModelUpdateOrder): Promise<AmazonOrder> {
    const data = JSON.stringify(modelUpdateOrder)
    return axios
      .post(url + 'api/amazon/order/update', data)
      .then(response => response.data)
  },

  /**
   * Récupération des type d'export amazon
   * @returns 
   */
  async getAmazonRefTypeExport(): Promise<AmazonRefTypeExport[]> {
    return axios
      .get(url + 'api/amazon/ref_type_export/get')
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },

  /**
 * Récupération des type d'export amazon
 * @returns 
 */
  async generateExportAmazon(idRefTypeExport: number): Promise<any> {
    return axios({
      url: url + 'api/amazon/ref_type_export/generate_export/' + idRefTypeExport,
      method: 'GET',
      responseType: 'blob',
    })
      .then((res) => {
        downloadFile(res)
      })
      .catch((error) => {
        console.log(error)
      });
  },

  /**
   * Récupération des stats tnt amazon
   * @param modelGenerateAmazonStats 
   * @returns 
   */
  async generateStatsTNTAmazon(modelGenerateAmazonStats: ModelGenerateAmazonStats): Promise<AmazonStatsType[]> {
    const data = JSON.stringify(modelGenerateAmazonStats)
    return axios
      .post(url + 'api/amazon/stat/get_shipping_stats', data)
      .then(response => response.data)
  },

  async generateFileStatsTNTAmazon(modelGenerateAmazonStats: ModelGenerateAmazonStats): Promise<any> {
    const data = JSON.stringify(modelGenerateAmazonStats)
    return axios({
      url: url + 'api/amazon/stat/get_shipping_stats_file',
      method: 'POST',
      responseType: 'blob',
      data: data
    })
      .then((res) => {
        downloadFile(res)
      })
      .catch((error) => {
        console.log(error)
      });
  },
  /*****************************************************************************************************************************/

  /***************************************** Admin My Garage *******************************************************************/
  /***************************************** Admin Ref Item  */

  async forceUpdateItem(forceUpdateItem: ForceUpdateItem): Promise<MGRefItem[] | ErrorGlobal> {
    const data = JSON.stringify(forceUpdateItem)
    return axios
      .post(url + 'api/my_garage/admin_ri/force_update_bdd', data)
      .then(response => response.data)
  },

  /***************************************** Admin Excluded integrated item */
  /**
   * Récupération des exclusion item catégorie code pour l'integration dans My garage
   * @returns 
   */
  async getExcludedItc(): Promise<ExcludedGlobal[]> {
    return axios
      .get(url + 'api/my_garage/admin_ee_itc/get')
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },
  /**
   * Maj ou création d'une exclusion de item categorie code pour l'integration dans my garage
   * @param excludedGlobal 
   * @returns 
   */
  async cuExcludedItc(excludedGlobal: ExcludedGlobal): Promise<ExcludedGlobal> {
    const data = JSON.stringify(excludedGlobal)
    return axios
      .post(url + 'api/my_garage/admin_ee_itc/create_or_update_ee_itc', data)
      .then(response => response.data)
  },
  /**
   * Suppression d'une exclusion de item categorie code pour l'integration dans my garage
   * @param idExcludedItc 
   * @returns 
   */
  async deleteItc(idExcludedItc: number): Promise<string> {
    return axios
      .delete(url + 'api/my_garage/admin_ee_itc/delete_ee_itc/' + idExcludedItc)
      .then(response => response.data)
  },
  /**
    * Récupération des exclusion product group code pour l'integration dans My garage
    * @returns 
    */
  async getExcludedPgc(): Promise<ExcludedGlobal[]> {
    return axios
      .get(url + 'api/my_garage/admin_ee_pgc/get')
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },
  /**
     * Maj ou création d'une exclusion de product group code pour l'integration dans my garage
     * @param excludedGlobal 
     * @returns 
     */
  async cuExcludedPgc(excludedGlobal: ExcludedGlobal): Promise<ExcludedGlobal> {
    const data = JSON.stringify(excludedGlobal)
    return axios
      .post(url + 'api/my_garage/admin_ee_pgc/create_or_update_ee_pgc', data)
      .then(response => response.data)
  },
  /**
     * Suppression d'une exclusion de product group code pour l'integration dans my garage
     * @param idExcludedItc 
     * @returns 
     */
  async deletePgc(idExcludedPgc: number): Promise<string> {
    return axios
      .delete(url + 'api/my_garage/admin_ee_pgc/delete_ee_pgc/' + idExcludedPgc)
      .then(response => response.data)
  },
  /**
    * Récupération des exclusion regroupement family pour l'integration dans My garage
    * @returns 
    */
  async getExcludedRf(): Promise<ExcludedGlobal[]> {
    return axios
      .get(url + 'api/my_garage/admin_ee_rf/get')
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },
  /**
      * Maj ou création d'une exclusion de regroupement family pour l'integration dans my garage
      * @param excludedGlobal 
      * @returns 
      */
  async cuExcludedRf(excludedGlobal: ExcludedGlobal): Promise<ExcludedGlobal> {
    const data = JSON.stringify(excludedGlobal)
    return axios
      .post(url + 'api/my_garage/admin_ee_rf/create_or_update_ee_rf', data)
      .then(response => response.data)
  },
  /**
      * Suppression d'une exclusion de regroupement family pour l'integration dans my garage
      * @param idExcludedItc 
      * @returns 
      */
  async deleteRf(idExcludedRf: number): Promise<string> {
    return axios
      .delete(url + 'api/my_garage/admin_ee_rf/delete_ee_rf/' + idExcludedRf)
      .then(response => response.data)
  },

  /***************************************** Admin Ref type customer group My Garage */
  /**
   * Récupération des enseigne de my garage
   * @returns 
   */
  async getRefCustomerGroupMG(): Promise<MGRefTypeCustomerGroup[]> {
    return axios
      .get(url + 'api/my_garage/admin_rcg/get_ref_type_customer_group')
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },
  /**
   * Création ou Maj d'une enseigne pour my garage
   * @param refTypeCustomerGroup 
   * @returns 
   */
  async cuRefTypeCustomerGroupMG(refTypeCustomerGroup: MGRefTypeCustomerGroup): Promise<any> {
    const data = JSON.stringify(refTypeCustomerGroup)
    return axios
      .post(url + 'api/my_garage/admin_rcg/create_or_update_ref_type_customer_group', data)
      .then(response => response.data)
  },
  /**
   * Suppresion d'une enseigne pour my garage
   * @param idRefTypeCustomerGroup 
   * @returns 
   */
  async deleteRefTypeCustomerGroupMG(idRefTypeCustomerGroup: number): Promise<string> {
    return axios
      .delete(url + 'api/my_garage/admin_rcg/delete_ref_type_customer_group/' + idRefTypeCustomerGroup)
      .then(response => response.data)
  },

  /****************************************************/

  /***************************************** Admin Item type erp excluded FOR CUSTOMER GROUP */
  /**
   * Récupération des famille d'exclusion de l'erp pour une enseigne
   * @param idRefTypeCustomerGroup 
   * @returns 
   */
  async getItemTypeErpExcludeds(idRefTypeCustomerGroup: number): Promise<MGItemTypeErpExcluded[]> {
    return axios
      .get(url + 'api/my_garage/admin_itee/get/' + idRefTypeCustomerGroup)
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },
  /**
   * Ajout d'une famille d'exclusion de l'erp pour une enseigne
   * @param addMGRefFamilyItemErp 
   * @returns 
   */
  async addItemTypeErpExcludeds(addMGRefFamilyItemErp: ADDMGRefFamilyItemErp): Promise<MGItemTypeErpExcluded> {
    const data = JSON.stringify(addMGRefFamilyItemErp)
    return axios
      .post(url + 'api/my_garage/admin_itee/add_itee', data)
      .then(response => response.data)
  },
  /**
   * Retrait d'une famille d'exclusion de l'erp pour une enseigne
   * @param idItemTypeErpExcluded 
   * @returns 
   */
  async removeItemTypeErpExcludeds(idItemTypeErpExcluded: number): Promise<string> {
    return axios
      .delete(url + 'api/my_garage/admin_itee/delete_itee/' + idItemTypeErpExcluded)
      .then(response => response.data)
  },

  /****************************************************/
  /***************************************** Admin Excluded Family FOR CUSTOMER GROUP */

  /**
    * Récupération des famille d'exclusion pour une enseigne
    * @param idRefTypeCustomerGroup 
    * @returns 
    */
  async getFamilyExcludeds(idRefTypeCustomerGroup: number): Promise<MGFamilyExcluded[]> {
    return axios
      .get(url + 'api/my_garage/admin_fe/get/' + idRefTypeCustomerGroup)
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },
  /**
   * Ajout d'une famille d'exclusion de l'erp pour une enseigne
   * @param addMGRefFamilyItemErp 
   * @returns 
   */
  async addFamilyExcludeds(addMGRefFamilyItemErp: ADDMGRefFamilyItem): Promise<MGFamilyExcluded> {
    const data = JSON.stringify(addMGRefFamilyItemErp)
    return axios
      .post(url + 'api/my_garage/admin_fe/add_fe', data)
      .then(response => response.data)
  },
  /**
    * Retrait d'une famille d'exclusion de l'erp pour une enseigne
    * @param idItemTypeErpExcluded 
    * @returns 
    */
  async removeFamilyExcludeds(idFamilyExcluded: number): Promise<string> {
    return axios
      .delete(url + 'api/my_garage/admin_fe/delete_fe/' + idFamilyExcluded)
      .then(response => response.data)
  },

  /****************************************************/


  /***************************************** Admin Excluded Family ERP FOR CUSTOMER GROUP */
  /**
   * Récupération des Famille erp qu'il est possible d'ajouter en exclusion à une enseigne
   * @param idRefTypeCustomerGroup 
   * @returns 
   */
  async getRefFamilyItemErpCanAddToCg(idRefTypeCustomerGroup: number): Promise<MGRefFamilyItemErp[]> {
    return axios
      .get(url + 'api/my_garage/admin_rfierp/get_can_add_to_cg/' + idRefTypeCustomerGroup)
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },

  /****************************************************/
  /***************************************** Admin Excluded Family FOR CUSTOMER GROUP */
  /**
   * Récupération des famille q'il est possible d'ajouter en exclusion à une enseigne
   * @param idRefTypeCustomerGroup 
   * @returns 
   */
  async getRefFamilyItemCanAddToCg(idRefTypeCustomerGroup: number): Promise<MGRefFamilyItem[]> {
    return axios
      .get(url + 'api/my_garage/admin_rfi/get_can_add_to_cg/' + idRefTypeCustomerGroup)
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },

  /****************************************************/


  /***************************************** Admin Users MG */
  /**
   * Récupération des utilisateur de my garage
   * @returns 
   */
  async getUsersMg(): Promise<MGUsers[]> {
    return axios
      .get(url + 'api/my_garage/admin_user/find_all')
      .then(response => response.data)
      .catch((error) => {
        console.log(error)
      });
  },

  /**Récupération des utilisateurs de my garage en fonction d'un model de recherche
   * 
   * @param modelSearchUser 
   * @returns 
   */
  async findUserBy(modelSearchUser: ModelSearchUser): Promise<PaginateMGUsers | ErrorGlobal> {
    const data = JSON.stringify(modelSearchUser)
    return axios
      .post(url + 'api/my_garage/admin_user/find_by', data)
      .then(response => response.data)
  },

  /****************************************************/

  /***************************************** Admin Ref Auto Center */
  /**
   * Recherche d'un centre auto de my garage
   * @param modelSearchCustomer 
   * @returns 
   */
  async findRefAutoCenter(modelSearchCustomer: ModelSearchCustomer): Promise<PaginateMGCustomers | ErrorGlobal> {
    const data = JSON.stringify(modelSearchCustomer)
    return axios
      .post(url + 'api/my_garage/admin_rac/find_rac', data)
      .then(response => response.data)
  },

  /**
   * Mise à jours d'un centre auto de my garage (commande bloqué)
   * @param modelUpateRefAutoCenter 
   * @returns 
   */
  async updateRefAutoCenter(modelUpateRefAutoCenter: ModelUpateRefAutoCenter): Promise<RefAutoCenter> {
    const data = JSON.stringify(modelUpateRefAutoCenter)
    return axios
      .post(url + 'api/my_garage/admin_rac/update_rac', data)
      .then(response => response.data)
  },

  /****************************************************/

  /*****************************************  Logs */
  /**
   * Récupération des logs d'un utilisateur de my garage
   * @param modelSearchLogs 
   * @returns 
   */
  async findLogsForUser(modelSearchLogs: ModelSearchLogs): Promise<PaginateMGLog | ErrorGlobal> {
    const data = JSON.stringify(modelSearchLogs)
    return axios
      .post(url + 'api/my_garage/log/find_by_user', data)
      .then(response => response.data)
  },


  /****************************************************/



  /****************************************************/



  /*****************************************************************************************************************************/


  /*****************************************  MODULE RETOUR *******************************************************************/

  async checkIsCustomerHaveStock(idErpCustomer: string): Promise<ModelResponseStatus> {
    return axios
      .get(url + 'api/return/check_is_customer_have_stock/' + idErpCustomer)
      .then(response => response.data);
  },

  async step1CreateReturn(modelCreateReturn: ModelCreateReturn): Promise<ModelResponseStatus> {
    const data = JSON.stringify(modelCreateReturn)
    return axios
      .post(url + 'api/return/step1_create_return', data)
      .then(response => response.data)
  },

  async step2GetStock(idErpCustomer: string): Promise<ModelResponseStatus> {
    return axios
      .get(url + 'api/return/step2_get_stock/' + idErpCustomer)
      .then(response => response.data);
  },

  async step3GetPurchase(idErpCustomer: string): Promise<ModelResponseStatus> {
    return axios
      .get(url + 'api/return/step3_get_purchase/' + idErpCustomer)
      .then(response => response.data);
  },

  async step4GetSales(idErpCustomer: string): Promise<ModelResponseStatus> {
    return axios
      .get(url + 'api/return/step4_get_sales/' + idErpCustomer)
      .then(response => response.data);
  },

  async step5GetEtai(idErpCustomer: string): Promise<ModelResponseStatus> {
    return axios
      .get(url + 'api/return/step5_get_etai/' + idErpCustomer)
      .then(response => response.data);
  },

  async step6CalcPreco(idErpCustomer: string): Promise<ModelResponseStatus> {
    return axios
      .get(url + 'api/return/step6_calc_preconisation/' + idErpCustomer)
      .then(response => response.data);
  },

  async getReturnData(idErpCustomer: string): Promise<ReturnData | null> {
    return axios
      .get(url + 'api/return/get_return/' + idErpCustomer)
      .then(response => response.data);
  },

  async getReturnPreco(idErpCustomer: string): Promise<ReturnPreco | null> {
    return axios
      .get(url + 'api/return/get_return_preco/' + idErpCustomer)
      .then(response => response.data);
  },

  async deleteReturn(idReturnData: number): Promise<ModelResponseStatus> {
    return axios
      .delete(url + 'api/return/delete_return/' + idReturnData)
      .then(response => response.data);
  },

  async updateReturnItemReco(modelUpdateReturnItemRecommandation: ModelUpdateReturnItemRecommandation): Promise<ReturnItemRecommandation> {
    const data = JSON.stringify(modelUpdateReturnItemRecommandation)
    return axios
      .patch(url + 'api/return_item_ecommandation/update', data)
      .then(response => response.data)
  },

  async getDetailStatReturnItemReco(idReturnItemRecommandation: number): Promise<StatsReturnItemRecommandation> {
    return axios
      .get(url + 'api/return_item_ecommandation/get_getail/' + idReturnItemRecommandation)
      .then(response => response.data);
  },

  async generateFileReturnItemReco(idReturnData: number, isPdf: number, type: number ): Promise<any> {
    return axios({
      url: url + 'api/return/generate_file/' + idReturnData + '/' + isPdf + '/' + type,
      method: 'GET',
      responseType: 'blob',
    })
      .then((res) => {
        downloadFile(res)
      })
      .catch((error) => {
        console.log(error)
      });
  },

  async sendFileReturnItemReco(idReturnData: number, email: string, isPdf: number, type: number): Promise<ModelResponseStatus> {
    return axios
      .get(url + 'api/return/send_email/' + idReturnData + '/' + email + '/' + isPdf + '/' + type)
      .then(response => response.data);
  },

  async getReturnItemsError(idErpCustomer: string): Promise<ReturnItemError[]> {
    return axios
      .get(url + 'api/return_item_error/get/' + idErpCustomer)
      .then(response => response.data);
  },

  async findItem(idErpCustomer: string, idErpItem: string): Promise<FindItem | null> {
    return axios
      .get(url + 'api/return_item_ecommandation/find_item/' + idErpCustomer + '/' + idErpItem.replace(/\s/g, ''))
      .then(response => response.data);
  },

  async getReturnItemRecoByRegroupementFamily(idErpCustomer: string, regroupementFamily: string): Promise<ReturnItemRecommandation[]> {
    return axios
      .get(url + 'api/return_item_ecommandation/get_by_regroupement_family/' + idErpCustomer + '/' + regroupementFamily)
      .then(response => response.data);
  },

  async getRefCarrier(weight: number): Promise<RefCarrier[]> {
    return axios
      .get(url + 'api/ref_carrier/get/' + weight)
      .then(response => response.data);
  },

  async setShippingData(modelSelectShipping: ModelSelectShipping): Promise<ModelResponseStatus> {
    const data = JSON.stringify(modelSelectShipping)
    return axios
      .post(url + 'api/return/set_shipping_data', data)
      .then(response => response.data)
  },

  async validReturn(idReturnData: number, idErpCustomer: string): Promise<ModelReturnOrderErp> {
    return axios
      .get(url + 'api/return/valid_return/' + idReturnData + '/' + idErpCustomer)
      .then(response => response.data);
  },

  async getHistoPurchase(idErpCustomer: string): Promise<ModelHistoPurchaseStockTypeEtai[][]> {
    return axios
      .get(url + 'api/return/get_histo_purchase/' + idErpCustomer)
      .then(response => response.data);
  },

  async getStockCustomer(idErpCustomer: string): Promise<ModelCustomerStock[]> {
    return axios
      .get(url + 'api/return/get_customer_stock/' + idErpCustomer)
      .then(response => response.data);
  },

  async getOrderCa(idErpCustomer: string): Promise<ModelCustomerOrderCa> {
    return axios
      .get(url + 'api/return/get_order_ca/' + idErpCustomer)
      .then(response => response.data);
  },

  async getOrders(idErpCustomer: string): Promise<ModelCustomerOrder[]> {
    return axios
      .get(url + 'api/return/get_orders/' + idErpCustomer)
      .then(response => response.data);
  },

  async getDetailOrder(idErpCustomer: string, idOrder: string): Promise<ModelCustomerOrderDetail> {
    return axios
      .get(url + 'api/return/get_details_order/' + idErpCustomer + '/' + idOrder)
      .then(response => response.data);
  },

  async updateReturnItemsSelected(idReturnData: number, idErpCustomer: string, isSelected : number) : Promise<ModelResponseStatus> {
    return axios
      .get(url + 'api/return/update_items_selected/' + idReturnData + '/' + idErpCustomer + '/' + isSelected)
      .then(response => response.data);
  },

  /***************************************** FIN MODULE RETOUR *******************************************************************/

};
