
import { defineComponent, ref } from "vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import { ModalBtnType } from "@/type/Modal/ModalType";
import {
  ModelCustomerDetailOrderLine,
  ModelCustomerOrderDetail,
} from "@/type/Return/ReturnType";
import ApiGestionCap from "@/services/ApiGestionCap";
import InputTextSite from "@/components/customInput/InputTextSite.vue";
import ServiceTable from "@/services/ServiceTable";
import { RouteName } from "@/constante/RouterConst";

export default defineComponent({
  name: "ModalDetailOrder",
  components: {
    TemplateModaleSite,
    InputTextSite,
  },
  setup() {
    const isOpen = ref(false as boolean);
    const saveButton = ref({
      label: "",
      affBtn: false,
    } as ModalBtnType);

    const isPending = ref(false as boolean);

    const modelCustomerOrderDetail = ref(
      null as ModelCustomerOrderDetail | null
    );

    const idOrder = ref(null as string | null);

    const rows = ref([] as ModelCustomerDetailOrderLine[]);

    const family = ref([] as string[]);

    const columns = [
      {
        name: "regroupementFamily",
        required: true,
        label:
          "message.customer.customer_orders.modal_detail_order.table.regroupementFamily",
        align: "left",
        field: (row: ModelCustomerDetailOrderLine) => row.regroupementFamily,
        sortable: true,
        classes: "fixedWidth",

      },
      {
        name: "idErpItem",
        required: true,
        label:
          "message.customer.customer_orders.modal_detail_order.table.idErpItem",
        align: "left",
        field: (row: ModelCustomerDetailOrderLine) => row.idErpItemFormated,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "vendorItemNo",
        required: true,
        label:
          "message.customer.customer_orders.modal_detail_order.table.vendorItemNo",
        align: "left",
        field: (row: ModelCustomerDetailOrderLine) => row.vendorItemNo,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "initialQty",
        required: true,
        label:
          "message.customer.customer_orders.modal_detail_order.table.initialQty",
        align: "center",
        field: (row: ModelCustomerDetailOrderLine) => row.initialQty,
        sortable: true,
      },
      {
        name: "qty",
        required: true,
        label: "message.customer.customer_orders.modal_detail_order.table.qty",
        align: "center",
        field: (row: ModelCustomerDetailOrderLine) => row.qty,
        sortable: true,
      },
    ];
    const filter = ref({
      regroupementFamily: "",
      idErpItem: "",
      vendorItemNo: "",
      initialQty: "",
      qty: "",
    });

    return {
      isOpen,
      saveButton,
      isPending,
      modelCustomerOrderDetail,
      idOrder,
      filter,
      rows,
      columns,
      family,
      ServiceTable,
    };
  },

  methods: {
    /**
     * fonction qui ouvre la modal
     */
    async modalOpen(idOrder: string, idErpCustomer: string) {
      this.modelCustomerOrderDetail = null;
      this.idOrder = idOrder;
      this.isPending = true;
      this.isOpen = true;
      try {
        this.modelCustomerOrderDetail = await ApiGestionCap.getDetailOrder(
          idErpCustomer,
          idOrder
        );
        this.rows = this.modelCustomerOrderDetail.modelCustomerDetailOrderLines;
        this.initFamily();
        this.isPending = false;
      } catch (error) {
        this.isPending = false;
        this.$router.push({ name: RouteName.Error });
      }
    },
    initFamily() {
      this.family = [];
      this.rows.forEach((row) => {
        if (!this.family.includes(row.regroupementFamily)) {
          this.family.push(row.regroupementFamily);
        }
      });
    },
    /**
     * fonction qui ferme la modal
     */
    modalClose() {
      this.isOpen = false;
    },
  },
});
