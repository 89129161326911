
import { computed, defineComponent, PropType, ref } from "vue";
import { AmazonOrder } from "@/type/Amazon/AmazonOrderType";
import { useI18n } from "vue-i18n";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import { AMAZ_CODE_ERR_PRICE,  AMAZ_CODE_ERR_STOCK} from "@/constante/ConstAmazonError";
import { AmazonOrderItem } from "@/type/Amazon/AmazonOrderItemType";
import { AmazonOrderError } from "@/type/Amazon/AmazonDataErrorType";


export default defineComponent({
name : 'OrderError',
props: {
    idOrder: {
      type: Object as PropType<AmazonOrder>,
      required: true,
    },
},
emits :['openModalRemoveOrder','openModalUpdateOrder'],

components:{
    ButtonSite
},
setup(props) {
    
    const { t } = useI18n();
    const pathTransError = 'message.amazon.order.error' as string;
   /**
     * flag permettant de savoir si la commande à besoin d'une action de l'utilisateur
     */
    const orderNeedUpdate = computed(()=>{
            let orderNeedUpdate = false;
            props.idOrder.orderErrors.forEach((orderError:AmazonOrderError) =>
            {
                if(!orderError.isFixed && orderError.idRefTypeError.fixByUserAction)
                {
                    orderNeedUpdate = true;
                }
            });
            return orderNeedUpdate;
    });
    

    return {
        orderNeedUpdate,
        t,
        AMAZ_CODE_ERR_PRICE,
        AMAZ_CODE_ERR_STOCK,
        pathTransError
    }


},
computed:{
    
   
    
},
methods:{

    openModalRemoveOrder(order : AmazonOrder){
        this.$emit('openModalRemoveOrder',order);
    },
     openModalUpdateOrder(order: AmazonOrder) {
      this.$emit("openModalUpdateOrder", order);
    },
    /*** libelle OrderError suivant le type d'erreur ****/
    libelleError(codeSite :string, idOrderItem :AmazonOrderItem|null){
        let libelle = '' as string;
        const pathTrans = this.pathTransError+'.libelle.'+ codeSite; 
        switch(codeSite) {
            case AMAZ_CODE_ERR_STOCK :
                libelle = this.$t(pathTrans,{
                    'sku' : idOrderItem?.sku , 
                    'qteOrder' : idOrderItem?.qty,
                    'qteErp' : idOrderItem?.stockReel
                    });
            break;
            case AMAZ_CODE_ERR_PRICE :
                libelle = this.$t(pathTrans,{
                    'sku' : idOrderItem?.sku , 
                    'priceAmazon' : idOrderItem?.price,
                    'priceErp' : idOrderItem?.priceNavision
                });
            break;
            default :
                libelle = this.$t(pathTrans);
        }
        return libelle;
    }
}


});


