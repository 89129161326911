import { RouteName } from '@/constante/RouterConst';
import ApiGestionCap from '@/services/ApiGestionCap';
import { Customer } from '@/type/CustomerType';
import { HeaderStepButtonType } from '@/type/HeaderStepButtonType';
import { FamilyReturnItemRecommandations, ModelDetailByFamily, ModelUpdateReturnItemRecommandation, ReturnData, ReturnItemRecommandation, ReturnPreco } from '@/type/Return/ReturnType';
import { defineStore } from 'pinia'

export const useReturnStore = defineStore({
    id: 'returnStore',
    state: () => ({
        customer: null as Customer | null,
        returnData: null as ReturnData | null,
        headerStepReturn: null as HeaderStepButtonType[] | null,
        returnPreco: null as ReturnPreco | null,
    }),
    getters: {
        getCustomer(state) {
            return state.customer;
        },
        getReturnData(state) {
            return state.returnData;
        },
        getHeaderStepReturn(state) {
            return state.headerStepReturn;
        },
        getReturnPreco(state) {
            return state.returnPreco;
        }
    },
    actions: {
        getMaxReturnAuthorized(): number {
            if (this.customer && this.returnPreco) {
                return this.returnPreco.authorizedAmount;
            }
            return 0;
        },
        getBalanceReturnAuthorized(): number {
            if (this.customer && this.returnPreco) {
                return this.returnPreco.authorizedAmount - this.getTotalAmountPreco();
            }
            return 0;
        },
        checkIfReturnAuthorizedAmountIsNotOver(priceToAdd: number): boolean {
            if (this.customer && this.returnPreco) {
                if (this.getTotalAmountPreco() + priceToAdd >= (this.returnPreco.authorizedAmount + this.returnPreco.authorizedOverAmount)) {
                    return false;
                }
                return true;
            }
            return false;
        },

        async resetReturn() {
            if (this.returnData) {
                //suppression de la return dans l'api
                await ApiGestionCap.deleteReturn(this.returnData.id);
                //nettoyage des données
                this.returnData = null;
                this.headerStepReturn = null;
                this.returnPreco = null;
            }
        },
        async initReturn(idCustomer: string) {
            this.initHeaderStepReturn();
            if (!this.getCustomer) {
                //récupération des données du customer
                await this.refreshCustomer(idCustomer);
            }
            //récupération des données de la return
            if (!this.getReturnData) {
                await this.refreshReturnData();
            }
        },
        async refreshReturnData() {
            if (this.customer) {
                this.returnData = await ApiGestionCap.getReturnData(this.customer.id);
            }
        },
        async refreshCustomer(idCustomer: string) {
            this.checkIfUserDataIsSameInStorage(idCustomer);
            this.customer = await ApiGestionCap.getCustomer(idCustomer);
        },
        async refreshReturnPreco() {
            if (this.customer) {
                this.returnPreco = await ApiGestionCap.getReturnPreco(this.customer.id);
            }
        },
        initHeaderStepReturn() {
            const emptyRoutes = [
                {
                    id: 1,
                    label: 'message.return.header_step_return.step_1',
                    route: RouteName.CreateReturnOrder,
                    params: {
                        idCustomer: this.customer?.id,
                        force: true
                    }
                },
                {
                    id: 2,
                    label: 'message.return.header_step_return.step_2',
                    route: RouteName.SelectReturnItem,
                    params: { idCustomer: this.customer?.id }

                },
                {
                    id: 3,
                    label: 'message.return.header_step_return.step_3',
                    route: RouteName.CheckReturnOrder,
                    params: { idCustomer: this.customer?.id }
                },
                {
                    id: 4,
                    label: 'message.return.header_step_return.step_4',
                    route: RouteName.SelectShipping,
                    params: { idCustomer: this.customer?.id }
                },
                {
                    id: 5,
                    label: 'message.return.header_step_return.step_5',
                    route: RouteName.ReturnValidation,
                    params: { idCustomer: this.customer?.id }
                }
            ] as HeaderStepButtonType[]
            this.headerStepReturn = emptyRoutes;
        },
        //mise à jour des préconisation + mise à jours du back
        async updateReturnPreco(ReturnItemRecommandation: ReturnItemRecommandation) {
            if (this.returnPreco) {
                //mise à jour de la préco avec l'id correspondant
                const index = this.returnPreco.modelFamilyReturnItemRecommandations.findIndex(f => f.regroupementFamily === ReturnItemRecommandation.regroupementFamily);
                if (index !== -1) {
                    const indexItem = this.returnPreco.modelFamilyReturnItemRecommandations[index].arrayReturnItemRecommandations.findIndex(i => i.id === ReturnItemRecommandation.id);
                    if (indexItem !== -1) {
                        this.returnPreco.modelFamilyReturnItemRecommandations[index].arrayReturnItemRecommandations[indexItem] = ReturnItemRecommandation;
                        //mise à jour du back
                        await this.updateReturnItemRecommandation(ReturnItemRecommandation);
                    }
                }

            }
        },
        async updateReturnItemRecommandation(returnItemRecommandation: ReturnItemRecommandation) {
            if (this.returnPreco && this.customer) {
                const modelUpdateReturnItemRecommandation = {
                    idErpCustomer: this.customer?.id,
                    idReturnItemRecommandation: returnItemRecommandation.id,
                    qtySelected: returnItemRecommandation.qtySelected,
                    qtyNoReturnable: returnItemRecommandation.qtyNoReturnable,
                    lineModified: returnItemRecommandation.lineModified
                } as ModelUpdateReturnItemRecommandation;

                await ApiGestionCap.updateReturnItemReco(modelUpdateReturnItemRecommandation);
            }
        },
        //fonction de récupération du total des qty séléctionnées
        getTotalQtySelected(): number {
            let totalQtySelected = 0;
            if (this.returnPreco) {
                this.returnPreco.modelFamilyReturnItemRecommandations.forEach((familyReturnItemRecommandations: FamilyReturnItemRecommandations) => {
                    familyReturnItemRecommandations.arrayReturnItemRecommandations.forEach((returnItemRecommandation: ReturnItemRecommandation) => {
                        totalQtySelected += returnItemRecommandation.qtySelected;
                    })
                })
            }
            return totalQtySelected;
        },
        //fonction de récupération du total de différente référence séléctionnées
        getTotalReferenceSelected(): number {
            let totalReferenceSelected = 0;
            if (this.returnPreco) {
                this.returnPreco.modelFamilyReturnItemRecommandations.forEach((familyReturnItemRecommandations: FamilyReturnItemRecommandations) => {
                    familyReturnItemRecommandations.arrayReturnItemRecommandations.forEach((returnItemRecommandation: ReturnItemRecommandation) => {
                        if (returnItemRecommandation.qtySelected > 0) {
                            totalReferenceSelected++;
                        }
                    })
                })
            }
            return totalReferenceSelected;
        },
        //fonction de récupération du montant total des préconisations
        getTotalAmountPreco(): number {
            let totalAmountPreco = 0;
            if (this.returnPreco) {
                this.returnPreco.modelFamilyReturnItemRecommandations.forEach((familyReturnItemRecommandations: FamilyReturnItemRecommandations) => {
                    familyReturnItemRecommandations.arrayReturnItemRecommandations.forEach((returnItemRecommandation: ReturnItemRecommandation) => {
                        totalAmountPreco += returnItemRecommandation.qtySelected * returnItemRecommandation.unitPriceHt;
                    })
                })
            }
            return totalAmountPreco;
        },
        //fonction de récupération du montant total des préconisations filtré par les références séléctionnées
        getReturnItemPrecoFilteredBySelected(): FamilyReturnItemRecommandations[] {
            const returnItemPrecoFilteredBySelected = [] as FamilyReturnItemRecommandations[];
            if (this.returnPreco) {
                this.returnPreco.modelFamilyReturnItemRecommandations.forEach((familyReturnItemRecommandations: FamilyReturnItemRecommandations) => {
                    const arrayReturnItemRecommandations = familyReturnItemRecommandations.arrayReturnItemRecommandations.filter((returnItemRecommandation: ReturnItemRecommandation) => {
                        return returnItemRecommandation.qtySelected > 0;
                    });
                    if (arrayReturnItemRecommandations.length > 0) {
                        returnItemPrecoFilteredBySelected.push({
                            regroupementFamily: familyReturnItemRecommandations.regroupementFamily,
                            arrayReturnItemRecommandations: arrayReturnItemRecommandations
                        });
                    }
                })
            }
            return returnItemPrecoFilteredBySelected;
        },
        //recupération des données pour le tableau de synthèse par famille
        getModelDetailByFamilys(): ModelDetailByFamily[] {
            const modelDetailByFamilys = [] as ModelDetailByFamily[];
            if (this.returnPreco) {
                const returnItemPrecoFilteredBySelected = this.getReturnItemPrecoFilteredBySelected();
                returnItemPrecoFilteredBySelected.forEach((familyReturnItemRecommandations: FamilyReturnItemRecommandations) => {
                    const amount = familyReturnItemRecommandations.arrayReturnItemRecommandations.reduce((acc, returnItemRecommandation) => {
                        return acc + returnItemRecommandation.qtySelected * returnItemRecommandation.unitPriceHt;
                    }, 0);
                    const qty = familyReturnItemRecommandations.arrayReturnItemRecommandations.reduce((acc, returnItemRecommandation) => {
                        return acc + returnItemRecommandation.qtySelected;
                    }, 0);
                    const modelDetailByFamily = {
                        regroupementFamily: familyReturnItemRecommandations.regroupementFamily,
                        countIdErpItem: familyReturnItemRecommandations.arrayReturnItemRecommandations.length,
                        qty: qty,
                        amount: amount,
                    } as ModelDetailByFamily;
                    modelDetailByFamilys.push(modelDetailByFamily);
                })

            }
            return modelDetailByFamilys;
        },
        //recupération des données pour le tableau de synthèse par référence
        getOnlyReturnItemRecoFilteredBySelected(): ReturnItemRecommandation[] {
            const onlyReturnItemRecoFilteredBySelected = [] as ReturnItemRecommandation[];
            if (this.returnPreco) {
                const returnItemPrecoFilteredBySelected = this.getReturnItemPrecoFilteredBySelected();
                returnItemPrecoFilteredBySelected.forEach((familyReturnItemRecommandations: FamilyReturnItemRecommandations) => {
                    familyReturnItemRecommandations.arrayReturnItemRecommandations.forEach((returnItemRecommandation: ReturnItemRecommandation) => {
                        onlyReturnItemRecoFilteredBySelected.push(returnItemRecommandation);
                    })
                })
            }
            return onlyReturnItemRecoFilteredBySelected;
        },

        //récupération du poid total des article séléctionnés
        getTotalWeightSelected(): number {
            let totalWeightSelected = 0;
            if (this.returnPreco) {
                this.returnPreco.modelFamilyReturnItemRecommandations.forEach((familyReturnItemRecommandations: FamilyReturnItemRecommandations) => {
                    familyReturnItemRecommandations.arrayReturnItemRecommandations.forEach((returnItemRecommandation: ReturnItemRecommandation) => {
                        totalWeightSelected += returnItemRecommandation.qtySelected * returnItemRecommandation.unitWeight;
                    })
                })
            }
            return totalWeightSelected;
        },
        checkIfUserDataIsSameInStorage(idCustomer: string) {
            if(this.customer?.id !== idCustomer){
                //clean all data in store
                this.customer = null;
                this.returnData = null;
                this.headerStepReturn = null;
                this.returnPreco = null;
            }
        }


    }
})