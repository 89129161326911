import { createI18n } from 'vue-i18n'

const numberFormats = {
  fr: {
    price: {
      style: 'currency', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, currency: 'EUR',
    },
    weight: {
      style: 'unit', unit: 'kilogram', unitDisplay: 'narrow', useGrouping: true, maximumFractionDigits: 2,
    },
  },
  en: {
    price: {
      style: 'currency', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true, currency: 'EUR',
    },
    weight: {
      style: 'unit', unit: 'kilogram', unitDisplay: 'narrow', useGrouping: true, maximumFractionDigits: 2,
    },
  }
}

const datetimeFormats = {
  fr: {
    nameMonthYear: {
      month: 'short',
      year: '2-digit',
    },
    monthYear: {
      month: 'numeric',
      year: 'numeric',
    },
    short: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    },
    long: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }
  },
  en: {
    nameMonthYear: {
      month: 'short',
      year: '2-digit',
    },
    monthYear: {
      month: 'numeric',
      year: 'numeric',
    },
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }
  }
}

const messages = {

  // FRENCH MESSAGES
  fr: {
    message: {
      mercure: {
        orderBatch: {
          processing: 'Le Batch {idOrderBatch} est en cours de traitement par {username}',
          unprocessing: "Le Batch {idOrderBatch} n'est plus en cours de traitement",
        },
      },
      dialog: {
        comfirm: 'Êtes-vous certain(e) de vouloir effectuer cette action ?',
        alert: 'Attention',
        info: 'Information'
      },
      globalError: "Désolé une erreur est survenue.<br />L'administrateur du site en a été averti.<br />Veuillez réessayer plus tard.",
      globalError401: "Vous n'avez pas les droits pour accéder à cette page.",
      hello: 'Bonjour',
      login: 'Connexion',
      logout: 'Déconnexion',
      input: {
        date: {
          error: "Date invalide (Format requie: JJ/MM/AAAA)"
        }
      },
      btn: {
        close: 'Fermer',
        save: 'Enregistrer',
        next: 'Suivant',
        send: 'Envoyer',
        previous: 'Précédent',
        search: 'Rechercher',
        resize: {
          minimise: 'Réduire',
          maximise: 'Agrandir',
        },
      },
      user: {
        code: 'Code client: {code}',
        name: 'Nom: {name}',
        phone: 'Téléphone: {phone}',
        email: 'Email: {email}',
        address: 'Adresse',
        contact: 'Contact: {contact}',
      },
      search: {
        searchGlobal: 'Rechercher',
        title: 'Formulaire de recherche',
        searchCustomer: {
          selectCustomerGroup: "Enseigne",
          postCode: 'Département',
          customerId: 'N° centre',
          error: {
            emptySearch: 'Un champs de recherche doit être renseigné au minimum',
            noAccess: 'Vous n\'avez pas accès à ce centre',
          }
        },
        error: {
          too_many_results: 'Trop de résultats, veuillez préciser votre recherche',
          no_result: 'Aucun résultat',
        }
      },
      customers: {
        table: {
          idErp: 'Identifiant Centre',
          name: 'Nom Centre',
          address: 'Adresse',
          phone: 'Téléphone',
          mg: {
            order_blocked: 'Blocage des commandes',
          }
        }
      },
      customer: {
        title: 'Centre',
        btn: {
          create_return: 'Créer un retour',
          customer_sheet: 'Fiche client',
          customer_purchase: 'Achats client',
          customer_stock: 'Stock client',
          customer_orders: 'Commandes client',
        },
        sheet: {
          title: 'Fiche du centre',
          customer_disc_group: 'Enseigne',
          customer_id: 'N° Client',
          customer_name: 'Nom',
          address: 'CP & Ville',
          phone: 'Tél.',
          email: 'Email',
        },
        customer_purchase: {
          no_data: "Aucune donnée disponible",
          table: {
            idErpItem: "Article",
            vendorItemNo: "Référence Courte",
            regroupementFamily: "Famille",
            totalPurchase: "Total achats",
            quantityRequested: "Requêtes Web",
            quantityStockType: "Stock type",
            averagePurchase: "Moyenne mensuelle",
          },
        },
        customer_stock: {
          no_data: "Aucune donnée disponible",
          btn: {
            error_stock: "Erreurs d’import fichier de stock",
          },
          table: {
            regroupementFamily: "Famille",
            idErpItem: "Article",
            vendorItemNo: "Référence Courte",
            stockQty: "Stock Total",
            stockAvaiblable: "Stock Disponible",
            qtyOnPurchaseOrder: "Quantité commande achat",
            qtyOnSalesOrder: "Quantité commande vente",
            qtyStockType: "Stock Type Paramétré",
          },
        },
        customer_orders: {
          orderCount: "Commandes {year}",
          ca: "CA {year}",
          averageOrder: "Panier moyen {year}",
          table: {
            orderNo: "N° commande",
            formatedDateOrder: "Date commande",
            orderAmount: "Montant commande",
            externalRef: "Réf. Externe",
          },
          modal_detail_order: {
            title: "Commande N°{orderNo}",
            orderNo: "N° commande : {orderNo} ({date})",
            externalRef: "Réf. Externe : {externalRef}",
            amount: "Montant : {amount}",
            table: {
              regroupementFamily: "Famille",
              idErpItem: "Article",
              vendorItemNo: "Ref. fournisseur",
              initialQty: "Quantité commandée",
              qty: "Quantité livrée",
            },
          },
        },
      },
      return: {
        return_validation: {
          message_return_validation_success: "Votre retour a bien été validé. Numéro de retour : {returnOrderNumber}",
          shipping_sheet: {
            title: "Récapitulatif de votre retour",
            carrier_name: "Transporteur",
            qty_package: "Nombre de colis/palettes",
            contact: "Contact",
            phone: "Téléphone",
            btn_validate: "Valider votre retour",
            btn_set_shipping: "Transport à valider",
          },
        },
        select_shipping: {
          form: {
            title: "Informations obligatoires à compléter pour l’enlèvement",
            accept_terms: "J'accepte les conditions de retour",
            qtyPackage: "Nombre de colis à enlever",
            qtyPallet: "Nombre de palettes à enlever",
            contact: "Contact",
            phone: "Téléphone",
            email: "Email",
            not_null_contact: "Le contact est obligatoire",
            not_null_phone: "Le téléphone est obligatoire",
            not_valid_phone: "Le téléphone n'est pas valide",
            not_null_email: "L'email est obligatoire",
            not_valid_email: "L'email n'est pas valide",
            not_valid_accept_terms: "Vous devez accepter les conditions de retour",
            error_backend: "Une erreur est survenue lors de la création de votre retour. Veuillez réessayer plus tard.",
          },
          selected_carrier: "Compte tenu du volume et du poids de votre retour, le transporteur sélectionné pour l’enlèvement est :",
          return_weight: "Poids total de votre retour : {weight}",
          btn_select_carrier: "Sélectionner un autre transporteur",
          info_package: {
            tnt: `<h4 >Conditionnement <b>TNT</b></h4>
              <p>Par colis carton individuel (ne dépassant pas les 30 kg)</p>
              <ul >
                <b>Attention :</b>
                <br>
                <li>une étiquette transport fournie par CAP PERFORMANCE devra être collée sur chaque colis.</li>
                <br>
                <li>une pénalité de 30 € sera facturée pour tout colis supérieur à 30 kg. (Loi sur le transport)</li>
                <br>
                <li>en cas de non remise de la marchandise au passage du transporteur, les frais de présentation inhérents au passage seront facturés au centre.</li>
              </ul>`,
            schen: `<h4 >Conditionnement <b>SCHENKER</b></h4>
            <p>Sur palette.</p>
						<ul >
							<b>Attention :</b>
							<br>
							<li>les produits doivent être préalablement regroupés dans des boites en carton posées sur palette.</li>
							<br>
							<li>la palette devra impérativement être filmée.</li>
							<br>
							<li>l’adresse de CAP PERFORMANCE devra être indiquée sur la palette.</li>
							<br>
							<li>en cas de non remise de la marchandise au passage du transporteur, les frais de présentation inhérents au passage seront facturés au centre.</li>
						</ul>`,
          }
        },
        header_step_return: {
          step_1: 'Analyse des données',
          step_2: 'Sélection des articles',
          step_3: 'Préparation de l’expédition',
          step_4: 'Sélection du transporteur',
          step_5: 'Validation finale',
        },
        check_return: {
          alert_not_all_return_item_checked: "Vous devez confirmer le nombre de références et les quantités pour passer à l'étape suivante.",
          detail_preco: {
            title: "Détail de votre retour",
            table: {
              regroupementFamily: "Famille",
              idErpItem: "Article",
              shortRef: "Référence courte",
              unitPriceHt: "Prix achat",
              qtySelected: "Quantité retournée",
              amount: "Montant"
            },
          },
          family_preco: {
            title: "Détail de votre retour par famille",
            table: {
              checked: "Contrôle CDS",
              regroupementFamily: "Famille",
              countIdErpItem: "Nombre de références",
              qty: "Quantités",
              amount: "Montant",
            },
          },
          general_packing_instruction: `<h4>Consignes générales d’emballage</h4>
                                          <p>
                                            Utiliser un ou plusieurs cartons rigides et en bon état.
                                            <br /><br />
                                            IMPORTANT : afin de ne pas endommager les produits, ne jamais mélanger
                                            les familles de produits entre elles. Utilisez un carton par famille
                                            de produit (carton contenant uniquement des produits de freinage et/ou
                                            carton contenant uniquement des produits de filtration ou autre
                                            produit léger).
                                            <br /><br />
                                            Caler parfaitement vos produits entre eux à l’aide de papier de calage
                                            ou de matériaux de rembourrage que vous aurez pris soin de compacter
                                            afin d’éviter tout mouvement à l’intérieur de vos cartons.
                                            <br /><br />
                                            Afin de respecter la règlementation en matière de transport, vos
                                            cartons ne doivent pas dépasser le poids de 30 kg (attention : une
                                            surtaxe de 30€ par colis de plus de 30kg vous sera appliquée en cas de
                                            dépassement).
                                            <br /><br />
                                            Placer le bordereau de livraison, détaillant vos produits (référence
                                            et quantité), à l’intérieur de votre colis ou sur votre colis dans une
                                            pochette transparente prévu à cet effet.
                                            <br /><br />
                                            Fermer votre colis en utilisant un rouleau de bande adhésive solide
                                            prévu pour l’expédition.
                                          </p>
                                          <h4>Pour les expéditions de colis individuels (via TNT)</h4>
                                  
                                          <p>
                                            Coller une étiquette d’expédition par colis (étiquette fournie par CAP
                                            PERFORMANCE).
                                            <br /><br />
                                            Chaque étiquette dispose d’un code barre unique.
                                            <br /><br />
                                            Pour une expédition comportant plusieurs colis, vous recevrez autant
                                            d’étiquettes que de colis annoncés.
                                            <br /><br />
                                            Le n° de colis sera précisé sur l’étiquette de la façon suivante,
                                            exemple pour 3 colis :
                                          </p>
                                  
                                          <ul>
                                            <li>Colis <b>1/3</b></li>
                                            <li>Colis <b>2/3</b></li>
                                            <li>Colis <b>3/3</b></li>
                                          </ul>
                                  
                                          <h4>Pour les expéditions de colis sur palette (via SCHENKER)</h4>
                                          <p>
                                            Rassembler les cartons sur la palette en prenant soin de mettre
                                            IMPERATIVEMENT les colis les plus lourds en dessous, jusqu’au moins
                                            lourds.
                                            <br /><br />
                                            Bien utiliser la surface complète de la palette pour chaque couche de
                                            produits, sans jamais faire dépasser les colis de la palette (risque
                                            de dégradation).
                                            <br /><br />
                                            Filmer la ou les palettes.
                                            <br /><br />
                                            Indiquer clairement l’adresse de CAP PERFORMANCE sur chaque palette de
                                            votre expédition.
                                            <br /><br />
                                            Attention : Sur le bordereau transporteur, l’unité d’expédition est
                                            <u>le nombre de palettes expédiées</u>
                                            (et non pas le nombre de colis contenus sur vos palettes).
                                          </p>`,
        },
        select_return: {
          error: {
            no_preco: "Aucune préconisation de retour n'est disponible pour ce centre.",
          },
          alert_no_return_item_selected: "Vous devez sélectionner au moins un article pour passer à l'étape suivante.",
          modal_return_item_by_family: {
            title: "Liste des articles en stock ERM",
            family: "Famille : ",
            table: {
              vendor: "Fournisseur",
              idErpItem: "Référence",
              shortRef: "Référence courte",
              designation: "Désignation",
              qtyStockCustomer: "Stock centre à date",
              isReturnable: "Retour possible",
              qtyStockReservedCustomer: "Stock réservé",
              qtyStockType: "Stock type paramétré",
              historicalQuantityBuyToCap: "Historique achat CAP",
              qtyPossibleToReturn: "Quantité maxi autorisée en retour",
            },
          },
          modal_find_item: {
            title: 'Information sur un article',
            id_erp_item: 'Référence',
            btn: "Rechercher",
            id_erp_item_required: "La référence est obligatoire",
            id_erp_item_not_found: "Aucun article trouvé avec cette référence",
            excluded: "Article exclus des retours marchandises",
            table: {
              vendor: "Fournisseur",
              regroupement_family: "Famille",
              ref: "Référence",
              description: "Désignation",
              qty_stock: "Stock centre à date",
              qty_reserved: "Stock réservé",
              qty_type: "Stock type paramétré",
              is_returnable: "Retour possible",
              historique_purchase: "Historique achat CAP",
              qty_returnable: "Quantité maxi autorisée en retour",
            }
          },
          modal_return_item_error: {
            title: 'Erreurs d’import fichier de stock',
            table: {
              idErpItem: 'Référence fournisseur',
              brandItemRef: 'Référence fabricant',
              customerItemRef: 'Référence nationale',
              codeEan: 'Code EAN',
              customerStockQty: 'Quantité en stock',
              error: "Type d'erreur",
              errorEan: 'Code EAN en erreur',
              errorRef: 'Aucune référence connue',
            }
          },
          modal_select_carrier: {
            title: "Sélection du transporteur",
          },
          modal_return_file: {
            title: "Fiche d'inventaire de retour de stock",
            type_choice: "Choisissez la séléction de votre fiche d'inventaire de retour de stock",
            format_choice: "Choisissez le format de votre fiche d'inventaire de retour de stock",
            email: "Adresse email de réception",
            not_null_email: "L'adresse email ne peut pas être vide",
            format_pdf: "PDF",
            format_excel: "Excel",
            send: "Envoyer",
            print: "Imprimer",
            error_global: "Une erreur est survenue lors de la génération de votre fiche d'inventaire de retour de stock",
            type: {
              all: "Liste complète",
              preco: "Liste préconisée",
              selected: "Liste sélectionnée",
            },
          },
          modal_stats_detail_return_item: {
            title: 'Historique article',
            family: 'FAMILLE : ',
            ref: 'RÉFÉRENCE ARTICLE : ',
            short_ref: 'RÉFÉRENCE COURTE : ',
            histo_purchase: 'Historique achats et interrogations de stock chez CAP PERFORMANCE',
            histo_sales: 'Historique sorties caisses du centre à la référence et sur références équivalentes concurrentes',
            integration_sales_date: 'Date des dernières sorties caisses communiquées à CAP : ',
            table: {
              purchase: 'Achats CAP',
              etai: 'Dispo',
              total: 'TOTAL',
              sales_item: 'Ventes à la réf',
              sales_substitute: 'Ventes réfs équivalentes',
            },
          },
          modal_authorized_amount: {
            title: 'Montant de retour autorisé',
            max_return_authorized: 'Le montant de retour autorisé pour le centre est de : {amount}',
            balance_authorized: 'Solde : {amount}',
          },
          btn: {
            next_step: 'Étape suivante',
            authorized_amount: ' Voir le montant de retour autorisé',
            print: 'Imprimer votre retour',
            email: 'Envoyer votre retour par email',
            search_item: 'Information sur un article',
            error_stock: 'Erreurs d’import fichier de stock ({count})',
            init_return: 'Initialiser votre retour',
            init_is_selected: 'Modifier votre retour',
          },
          amount: 'Montant pièces sélectionnées : {amount}',
          qty_selected_item: 'Total références sélectionnées : {qty}',
          qty_selected: "Total quantités sélectionnées : {qty}",
          family_preco: {
            item_available: "Références éligibles : {count}",
            item_preco: "Références préconisées :",
            item_selected: "Références sélectionnées : {count}",
            amount: "Montant : {amount}",
            table: {
              list_stock: "Liste des articles en stock ERM",
              alert: {
                selected_reserved: "Vous allez retourner des pièces potentiellement réservées.",
                overt_amount: "Vous ne diposez pas d'un solde suffisant pour ajouter cette pièce à votre retour.",
                can_not_return: "Vous ne pouvez pas dépasser la quantité autorisée pour cette pièce.",
                title_unhautorized: 'Action non autorisée',
                selected_stock_type: `Cet article est un stock type.<br><br>
                Vous devez avoir une quantité minimum de {count} en stock.
                `
              },
              hidden_line_modified: "Masquer les lignes traitées",
              view_all_line: "Afficher toutes les lignes",
              detail: "Voir le Détail",
              idErpItem: "Article",
              shortRef: "Référence courte",
              stock_customer: "Stock centre",
              stock_reserved: "Stock réservé",
              stock_type: "Stock type paramétré",
              purchase: "Historique achat CAP",
              last_purchase_date: "Date de dernière commande",
              unit_price: "Prix unitaire",
              preco: "Article préconisé",
              max_available_qty: "Quantité maxi. autorisée",
              qty_selected: "Quantité retournée",
              amount: "Montant",
              item_no_returnable: "Quantité non reprenable (packaging HS)",
              check: "Traité",
            },
          }
        },
        ask_refresh_return: `<p>Attention,<br><br>
                Un retour de type manuel est en cours de génération.
                <br>La création de votre nouveau retour entrainera sa suppression.
                <br><br>Souhaitez-vous continuer la création de votre nouveau retour manuel ?
            </p>`,
        btn: {
          calc_return: 'Calculer le retour',
          reset_return: 'Réinitialiser le retour',
        },
        error: {
          no_stock: "Aucun stock n'est disponible pour ce centre.",
        },
        step1: {
          title: 'Préparation du retour',
          desc: "Récupération des informations du centre",
        },
        step2: {
          title: 'Analyse des stocks',
          desc: "Analyse de vos stocks et stocks types en date du jour afin d'optimiser notre préconisation de retour.",
          error: "Une erreur est survenue lors de l'analyse des stocks. Veuillez réessayer plus tard.",
        },
        step3: {
          title: 'Analyse des achats',
          desc: "Analyse des achats réalisés chez CAP Performance sur les articles actuellement en stock dans votre centre.",
        },
        step4: {
          title: 'Analyse des ventes',
          desc: "Analyse de vos ventes sur les 12 derniers mois afin d'identifier vos articles à faible rotation.",
        },
        step5: {
          title: 'Analyse des requêtes Atelio',
          desc: "Analyse de vos interrogations de stock Atelio sur les 12 derniers mois sur les articles actuellement en stock dans votre centre.",
        },
        step6: {
          title: 'Création de votre retour',
          desc: "Compilation des données et calcule de la préconisation.",
        },
      },
      customer_groups: {
        table: {
          idErp: 'Code enseigne ERP',
          libelle: 'Nom du l\'enseigne',
          logo: 'Logo',
          blocked: 'Status'
        }
      },
      modalUpdateCustomerGroup: {
        title: "Mise à jour de l'enseigne",
        btn: "Envoyer",
        btnDelete: "Supprimer l'enseigne",
        btnCreate: "Création d'un enseigne",
        error: {
          create: "Pour faire la création d'un enseigne vous avez besoin au minimum d'un identifiant ERP et d'un libelle",
          update: "Pour faire la modification d'une enseigne vous avez besoin au minimum d'une champ renseigné",
        },
        form: {
          idErp: 'Identifiant',
          libelle: 'Nom de l\'enseigne',
          blocked: 'Bloquer ?',
          validator: {
            idErp: {
              notNull: "L'identifiant de l'enseigne ne doit pas etre vide.",
            },
            libelle: {
              notNull: "Le nom de l'enseigne ne doit pas etre vide.",
            },
          },
        }
      },
      create_user: {
        form: {
          fileCsv: "Fichier CSV",
          upload: "Importer les utilisateurs",
        },
        table: {
          username: "Pseudo",
          firstName: "Prénom",
          lastName: "Nom",
          email: "Email",
          erpId: "ERP No°",
          isManager: "Manager Centre ?",
          idKeycloak: "SSO No°",
          createdInKeycloak: "Ajout SSO ?",
          createdInMyGarage: "Ajout My Garage ?",
        },
        error: {
          missingCsv: "Merci de selectionner un fichier CSV d'import d'utilisateur"
        },
        report: {
          csvUsers: "Nombre d'utilisateur présent dans le CSV: {qty}",
          csvManagerMyGarage: "Nombre d'utilisateur Manager présent dans le CSV: {qty}",
          csvUsersImportedKeycloak: "Nombre d'utilisateur importé dans keycloak: {qty}",
          csvUsersImportedMyGarage: "Nombre d'utilisateur importé dans My Garage: {qty}",
        },
      },
      router: {
        title: {
          home: '<q-icon name="o_home" />',
          admin: 'Administration',
          customers: 'Centres',
          customer: 'Centre',
          create_return_order: "Création d'un retour",
          select_return_order: "Sélectionnez vos articles à retourner",
          gestion_customer_group: 'Gestion des enseignes',
          other_univers: 'Autres univers',
          extern_equipement_atelier: 'Équipement d\'Atelier',
          extern_my_garage: 'My Garage',
          create_user: "Création d'utilisateurs",
          my_garage: "My Garage Administration",
          excluded_item: "Gestion des exclusions d'integration d'article",
          gestion_customer_group_mg: "Gestion des enseignes My Garage",
          gestion_users_mg: "Gestion des utilisateurs",
          gestion_rac: "Gestion des centres auto",
          amazon_orders: "Commandes Amazon",
          amazon_orders_detail: "Détails des commandes Amazon",
          amazon_shipping_stats: "Performance transporteur TNT sur expéditions DropShip BOSCH Amazon",
          amazon_manage_shipping_remainder: "Gestion des reliquats de livraison",
          gestion_force_update_item: "Importation forcée d’articles ERP dans My Garage",
          check_return_order: "L’essentiel pour l’expédition de votre retour",
          select_shipping: "Organisation de l'enlèvement marchandise",
          return_validation: "Validation de votre retour",
          customer_purchase: "Historique des achats",
          customer_stock: 'Stock client',
          customer_orders: 'Commandes client',
        }
      },
      contact: {
        success: 'Votre message a été envoyé',
        title: 'Demande de contact',
        contactUs: 'Contactez-nous',
        form: {
          globalError: 'Veuillez remplir le formulaire correctement :',
          subject: {
            label: 'L\'objet de votre demande de contact',
            validator: {
              global: 'Veuillez préciser l\'objet de votre demande de contact',
              notNull: 'L\'objet de votre demande de contact est obligatoire',
            }
          },
          customSubject: {
            label: 'Merci de remplir l\'objet de votre demande',
            validator: {
              global: 'L\'objet de votre demande de contact est obligatoire (minimum 10 caractères)',
              notNull: 'Si vous avez choisi "Autre", merci de préciser l\'objet de votre demande de contact',
              length: 'Votre object doit contenir entre 10 et 100 caractères',
            }
          },
          name: {
            label: 'Votre nom',
            validator: {
              global: 'Votre nom est obligatoire et doit contenir moin de 100 caractères',
              notNull: 'Le nom est obligatoire',
              length: 'Votre nom doit contenir moin de 100 caractères',
            }
          },
          firstName: {
            label: 'Votre prénom',
            validator: {
              global: 'Votre prénom est obligatoire et doit contenir moin de 100 caractères',
              notNull: 'Le prénom est obligatoire',
              length: 'Votre prénom doit contenir moin de 100 caractères',
            }
          },
          email: {
            label: 'Votre adresse email',
            validator: {
              global: 'L\'adresse email est obligatoire',
              notNull: 'L\'adresse email est obligatoire',
              regex: 'L\'email est invalide',
            }
          },
          tel: {
            label: 'Votre numéro de téléphone',
            validator: {
              global: 'Le numéro de téléphone est obligatoire et doit être un numéro de français valide',
              notNull: 'Le numéro de téléphone est obligatoire',
              regex: 'Le numéro de téléphone est invalide',
            }
          },
          textRequest: {
            label: 'Votre demande ( minimum 10 caractères )',
            validator: {
              global: 'Votre demande est obligatoire et doit contenir au moins 10 caractères',
              notNull: 'Votre demande est obligatoire',
              length: 'Votre demande doit contenir moin de 10 caractères',
            }
          }
        }
      },
      modal_create_update_excluded: {
        form: {
          code: "Code de l'exclusion",
          validator: {
            code: {
              not_null: "le code de l'exclusion ne peut pas être vide"
            }
          }
        },
        title: 'Exclusion',
        btn_create: "Ajout d'une exclusion",
        btn_save: "Envoyer"
      },
      excluded_item: {
        itc: 'Famille',
        pgc: 'Sous Famille',
        rf: 'Famille de regroupement',
      },
      table_excluded_global: {
        id: 'Identifiant',
        code: 'Code',
        delete: 'Supprimer'
      },
      gestion_customer_group_mg: {
        btn_create: "Création d'une enseigne",
        table: {
          idErp: 'Identifiant enseigne ERP',
          libelle: "Nom de l'enseigne",
          logo: 'Logo',
          accessExternalDocumentNo: "Accès a l'option référence interne",
          limitOrderPriceNeedValidation: "Validation des commandes supérieures au seuil",
          limitPriceItemCanBuy: "Limite de prix d'achat par article",
          no_limit: "Aucune limite",
        },
        modal: {
          title: "Enseigne",
          save_btn: "Envoyer",
          btn_delete: "Supprimer l'enseigne",
          form: {
            idErp: 'Identifiant',
            libelle: "Nom de l'enseigne",
            accessExternalDocumentNo: "Accès a l'option référence interne",
            limitOrderPriceNeedValidation: "Validation des commandes supérieures au seuil",
            limitPriceItemCanBuy: "Limite de prix d'achat par article",
            validator: {
              libelle: {
                notNull: "Le nom de l'enseigne ne doit pas être vide.",
              },
              idErp: {
                notNull: "L'identifiant de l'enseigne ne doit pas être vide.",
              },
            }
          }
        },
        family_excluded: {
          btn_open: "Ouvrir la liste des familles exclues",
          btn_create: "Ajouter une famille exclue",
          modal: {
            title: "Gestion des familles exclues",
            btn_delete: "Supprimer la famille exclue",
            add_title: "Ajouter une famille exclue",
            add_select: "Sélectionner une famille exclue",
            add_send: "Ajouter une famille exclue",
            add_value: "Famille : {libelle} {fullLibelle}",
          },
          table: {
            libelle: "Libellé",
            fullLibelle: "Arbre position famille",
            delete: "Suppression",
          }
        },
        item_type_erp_excluded: {
          btn_create: "Ajouter une exclusion de famille ERP",
          btn_open: "Ouvrir la liste de familles ERP exclues",
          modal: {
            title: "Exclusion de famille ERP",
            btn_delete: "Supprimer",
            add_title: "Ajouter une exclusion de famille ERP",
            add_select: "Sélectionnez une famille ERP",
            add_value: "Famille: { libelle }, Code famille: { itemCategoryCode },  Code souc-famille: { code }"
          },
          table: {
            libelle: "Nom",
            itemCategoryCode: "Code famille",
            code: "Code sous-famille",
            delete: "Supprimer",
          }
        }
      },
      users_mg: {
        table: {
          username: "Nom d'utilisateur",
          firstName: "Prénom",
          lastName: "Nom de famille",
          email: "Adresse email",
          idRefAutoCenterERP: "ID centre auto",
          nameRefAutoCenter: "Nom du centre auto",
          customerGroup: "Groupe client",
          dateLastLogin: "Date de dernière connexion",
          accessLog: "Journaux utilisateur",
        },
        logs: {
          btn_open: "Ouvrir les journaux",
          modal: {
            title: "Journaux utilisateur",
          },
          table: {
            date: "Date/Heure",
            libelle: "Libellé",
            detail: "Détails",
          },
          detail: {
            immat: "Immatriculation :",
            vehicleVariations: "Variations du véhicule :",
            isOutOfCatalog: "En dehors du catalogue",
            searchText: "Texte de recherche :",
            idRefTypeItem: "Type d'article :",
            idRefFamilyItem: "Famille d'article :",
            idRefTypeRepair: "Type de réparation :",
            totalPriceOrderItems: "Prix total :",
            externalDocumentNo: "Numéro de document externe :",
            erpOrderIds: "ID de commande ERP :"
          }
        }
      },
      amazon: {
        order_batch: {
          alert_final_check: {
            title: "Contrôle Final d'intégration",
            content: "Commande AMAZON : {webOrderId} retrouvé en {nbIdenticalOrder} exemplaire dans l'erp: {erpOrderNo}",
            btn: 'Relancer le contrôle final',
          },
          error_final_check: "Des Doublons ont été trouver pendant le contôle final d'intégration.",
          error_import: "Une erreur c'est produite pendant l'intégration de la commande {amazonId}. Merci de raffraichir la page.",
          alert_processing: "Batch en cours de traitement par {username}",
          delete: "BATCH VIDE",
          comfirm_delete: "Êtes-vous sûr(e) de vouloir supprimer le batch n°{id}",
          order_duplicate: "Commande en doublon ({count})",
          shipping_stats: "Statistiques Performance CAP et Transport",
          info_table: "<span class='blockInfoTable'>Chaque commande étiquetée <i class='q-icon text-positive notranslate material-icons binariIcon' aria-hidden='true' role='presentation'>verified</i> sera intégrée dans l'ERP</span><br><span class='blockInfoTable'>Chaque commande étiquetée  <i class='q-icon text-negative notranslate material-icons binariIcon' aria-hidden='true' role='presentation'>dangerous</i> ne sera pas intégrée dans l'ERP</span><br><span class='blockInfoTable'>Chaque commande étiquetée <i class='q-icon text-positive notranslate material-icons binariIcon' aria-hidden='true' role='presentation'>browser_updated</i> est déjà intégrée dans l'ERP</span>",
          number: "Batch N°{idOrderBatch}",
          import_info: "Importer le {date} par {name}",
          import_erp: "Importer les commandes dans l'ERP",
          import_file_csv: "Importer un nouveau fichier",
          import_in_progess: "Import en cours, ne pas quitter la page",
          import_btn: "Importer",
          id: 'N° BATCH',
          name: 'Utilisateur',
          name_user_site: "Nom de l'utilisateur du site",
          date_import: "Date import",
          date_integration_start: "Date intégration ERP",
          date_integration_end: "Date de fin d'intégration",
          date_export_package_shipped: "Date export colis expédiés",
          date_export_package_current_delivery: "Date export colis en cours de liv.",
          date_export_package_tnt_status: 'Date export status TNT',
          status: 'Statut',
          duplicate: 'Doublon de commande',
          error: {
            missing_csv: 'Pas de fichier à importer'
          },
          search: {
            error_global: "Les dates doivent être renseignés et valides."
          },
          export: {
            libelle: "Export",
            date: "(Dernier fichier généré le {date})",
            comfirm: {
              shipped: `
                <div class="exportAlert">
                  <span class="title">VIGILANCE POUR FICHIER COLIS EXPÉDIÉS</span>
                  <u>
                      <span class="WARNING">À IMPÉRATIVEMENT généré le jour de l'expédition des colis (en J)</span>
                  </u>
                  <p>
                    <ul>
                      <li><b>Il regroupe désormais TOUS les colis expédiés dans la journée</b> = toutes les commandes intégrées dans NAV dans la journée, à travers tous les batches du jour.</li>
                      <li>Si vous NE le générez <b>exceptionnellement</b> PAS un jour, vous devrez contacter l'IT CAP afin de l'obtenir.</li>
                      <li>Merci d'enregistrer le fichier tel qu'il est <b>désormais</b> intitulé quand vous l'ouvrez = <b>EXPES_AMAZON_BOSCH_jjmmaaaa.csv</b> (avec jjmmaaaa = date d'expédition des commandes = date du jour de génération du fichier)</li>
                    </ul>
                  </p>
                </div>
              `,
              progress_delivery: `
              <div class="exportAlert">
                <span class="title">VIGILANCE POUR FICHIER COLIS EN COURS DE LIVRAISON</span>
                <p>
                    <ul>
                        <li><b>Il regroupe désormais TOUS les colis expédiés la veille</b> = toutes les commandes intégrées dans NAV la veille, à travers tous les batches de la veille.</li>
                        <li>Si vous NE le générez <b>exceptionnellement</b> PAS un jour, vous devrez contacter l’IT CAP afin de l’obtenir. </li>
                        <li>Merci d'enregistrer le fichier tel qu'il est <b>désormais</b> intitulé quand vous l'ouvrez = <b>EN_COURS_LIVR_AMAZON_BOSCH_jjmmaaaa.csv</b> (avec jjmmaaaa = date d’expédition des commandes = date de la veille "ouvrée")</li>
                    </ul>
                </p>
              </div>
              `,
              tnt_status: `
                <div class="exportAlert">
                    <span class="title">Fichier des STATUTS</span>
                    <p>
                        <ul>
                            <li>A générer IMPERATIVEMENT quotidiennement en fin de journée (si EXCEPTIONNELLEMENT vous ne l’avez pas fait, contactez l'IT)</li>
                            <li><span class="WARNING">Supprimez les 3 dernières colonnes du fichier</span> (ajoutées pour vous faciliter le suivi) <span class="WARNING">avant de l’intégrer dans VENDOR CENTRAL</span></li>
                            <li><b>Dès le lendemain matin, contactez le transporteur concerné pour débloquer tous les colis n’ayant pas atteint de statut DEFINITIF dans ce fichier STATUTS.</b></li>
                        </ul>
                    </p>
                </div>
              `,
              tnt_rest_status: `
              <div class="exportAlert">
                  <span class="title">Fichier des RELIQUATS</span>
                  <p>
                      <br>    
                      <ul>
                          <li><b>Dans la journée écoulée, vous avez contacté le transporteur concerné et œuvré pour débloquer un maximum de RELIQUATS.</b></li>
                          <li><b>Les seuls RELIQUATS qui se trouvent dans ce fichier sont ceux qu'il vous a été impossible de débloquer ce jour.</b></li>
                          <li>A générer IMPERATIVEMENT quotidiennement en fin de journée (si EXCEPTIONNELLEMENT vous ne l'avez pas fait, contactez l'IT)</li>
                          <li><span class="WARNING">Veuillez supprimez les 3 dernières colonnes du fichier</span> (ajoutées pour vous faciliter le suivi) <span color="WARNING">avant de l'intégrer dans VENDOR CENTRAL</span></li>
                      </ul>
                  </p>
              </div>
              `,
            }
          }
        },
        manage_shipping_status_remainder: {
          table: {
            type: "Type de commande",
            shipping_date: "Date d'expédition",
            amazon_order_id: "Numéro de commande",
            carrier: "Transporteur",
            full_packing_no: "N° Bon de transport",
            new_status: "Statut",
            new_date_status: "Date du statut",

          },
          btn: {
            save: "Enregistrer les modifications",
          },
          form: {
            input: {
              select_new_status: {
                placeholder: "choisir un statut",
              }
            },
            error: 'Merci de renseigner les statuts ainsi que leur date de statut.'
          }
        },
        order_batch_detail: {
          date_order: "Date commande: {date}",
          date_req_ship: "Date d'envoi requise: {date}",
          phone: "Tel: {phone}",
          sku: "Référence",
          stock: "Stock",
          price: "Prix",
          erp_order_no: "N° Commande : {erpOrderNo}",
          erp_integrated_date: "Date d'intégration ERP: {date}",
          error: {

            address: "<span class='libelle'>ERREUR ADRESSE: </span>Adresse invalide",
            phone: "<span class='libelle'>ERREUR TELEPHONE: </span>Numéro de téléphone invalide.",
            stock: "<span class='libelle'>ERREUR STOCK: </span>Commande automatiquement supprimée."
          },
          table: {
            amazon_id: "Infos commande",
            date_req_ship: "Date de demande d'expédition",
            receiver: "Destinataire",
            order_items: "Articles de la commande",
            status: "Statut de la commande",
            info: "Details"
          }
        },
        order: {
          remove: {
            btn_open: "Supprimer la commande",
            modal: {
              title: 'Supression d\'une commande',
              txt: 'Êtes-vous sûr de vouloir supprimer la commande {AmazonId}',
              btn_save: 'Supprimer la commande'
            }
          },
          error: {
            titre: {
              c_err_date: "ERREUR DATE COMMANDE",
              c_err_state: "ERREUR ETAT/REGION",
              c_err_cp: "ERREUR CODE POSTAL",
              c_err_phone: "ERREUR N° TELEPHONE",
              c_err_size_Adr: "ERREUR ADRESSE",
              c_err_price: "ERREUR DE PRIX",
              c_err_stock: "ERREUR STOCK",
              c_err_country: "ERREUR PAYS",
              c_err_double: "ERREUR DOUBLON"
            },
            libelle: {
              c_err_date: "Vérifier qu'il ne s'agit pas d'une commande annulée.",
              c_err_state: "La données Etat/Région de l'adresse est remplie.",
              c_err_cp: "Le code postale renseigné est hors France Métropolitaine.",
              c_err_phone: "Corriger le numéro de téléphone.",
              c_err_size_Adr: "Au moins une données de l'adresse est trop longue.",
              c_err_price: "Le prix de l'article {sku} est de {priceAmazon} € au lieu de {priceErp} € ",
              c_err_stock: "Stock insuffisant {sku} : {qteOrder}/{qteErp}",
              c_err_country: "Code pays <> de FR",
              c_err_double: "Commande déjà importée dans le site."
            }

          },
          update: {
            btn_open: "Mettre à jour la commande",
            modal: {
              btn_save: "Mettre à jour la commande",
              title: "Mise à jour de la commande",
              form: {
                name: "Nom",
                addr1: "Adresse 1",
                addr2: "Adresse 2",
                addr3: "Adresse 3",
                zip_code: "Code postal",
                city: "Ville",
                phone: "Téléphone",
                country: "Pays",
                region: 'État / département',
                validator: {
                  name: {
                    maxLen: "Le nom ne peut pas dépasser {maxLen} caractères. {len}/{maxLen}"
                  },
                  addr1: {
                    maxLen: "L'adresse 1 ne peut pas dépasser {maxLen} caractères. {len}/{maxLen}"
                  },
                  addr2: {
                    maxLen: "L'adresse 2 ne peut pas dépasser {maxLen} caractères. {len}/{maxLen}"
                  },
                  addr3: {
                    maxLen: "L'adresse 3 ne peut pas dépasser {maxLen} caractères. {len}/{maxLen}"
                  },
                  zip_code: {
                    error_global: "Le code postal ne peut pas dépasser {maxLen} caractères et doit se situer en France métropolitaine ou Monaco. {len}/{maxLen}"
                  },
                  city: {
                    maxLen: "La ville ne peut pas dépasser {maxLen} caractères. {len}/{maxLen}"
                  },
                  phone: {
                    not_valide_phone: "Le numéro de téléphone doit être au format français."
                  },
                  country: {
                    not_fr: "Le code du pays enregistrer doit être obligatoirement FR"
                  },
                  region: {
                    not_null: "Le champs doit toujours rester vide"
                  }
                }
              }
            }
          }
        },
        shipping_stats: {
          btn_generate_file: "Télécharger les statistiques d'expédition d'Amazon",
          Caption: {
            title: "Légende :",
            ok: "Engagements respectés",
            ko: "Engagements non respectés",
            sub: "Sous-totaux",
          },
          table: {
            order_shipped: "Commande Expédiées du {start_date} au {end_date} inclus",
            quantity: "quantité",
            total_orders: "Nombre total de commandes expédiées sur la période analysée",
            presentation_rate: "Taux de PRÉSENTATION DESTINATAIRE à J+1",
            reminder: "RAPPEL",
            cap_commitments: "Engagement CAP = expédition à J+1 … du lundi au vendredi",
            carrier_commitments: "Engagement transporteur = livraison à J+1 du lundi au vendredi",
            stats_header: "STATS OBTENUES AVEC LES STATUTS VENDOR CENTRAL ACTUELS",
            statement_quantity: "quantité de l'énoncé",
            statement_percentage: "% quantité de l'énoncé",
            delivered_quantity: "quantité PRÉSENTATION DESTINATAIRE à J+1",
            delivered_percentage: "% quantité PRÉSENTATION DESTINATAIRE à J+1 (vs quantité de l'énoncé)",
            j1: "J+1",
            j2: "J+2",
            j3: "J+3 et au-delà",
            delivered_to: "Nombre de commande Livrée à",
            delivered_to_j1: "Nombre de commande Livrée à J+1",
            refused_by_recipient: "Nb de commandes REFUSÉES par le destinataire",
            unfeasible_quantity: "Nb de commandes NON livrables à :",
            lost_quantity: "Nb de commandes PERDUES à :",
            delayed_quantity: "Nb de commandes RETARDÉES (causes destinataire ou transporteur ou force majeure) à :",
            delivering_quantity: "Nb de commandes EN COURS DE LIVRAISON à :",
            total_engagement: "TOTAL COMMANDES LIVREES ET REFUSEES :",
            total_non_engagement: "TOTAL COMMANDES AUTRES :",
            total: "TOTAL",
            type_order: "Type de commandes ci-dessous à la date ci-contre :",
            refused_by_customer: `Nb de commandes REFUSEES par le destinataire à :
                                                        (et NON
                                                        livrées et NON présentées avant)`,
            total_final: "Nombre total de commandes traitées"
          }
        }
      },
      my_garage: {
        force_update_item: {
          btn_update: "Forcer la mise à jours",
          count_updated: "Nombre d'article : {count}",
          error_global: "Les dates doivent être renseignés et valides.",
          no_result: "Aucun article crée ou mis à jours",
          table: {
            idErp: 'Identifiant ERP',
            libelle: 'Libelle',
            brand: 'Marque'
          }
        }
      }
    }

  },


  // ENGLISH MESSAGES
  en: {
    message: {
      mercure: {
        orderBatch: {
          processing: 'Batch {idOrderBatch} is being processed by {username}',
          unprocessing: "Batch {idOrderBatch} is no longer being processed",
        },
      },
      dialog: {
        comfirm: 'Are you sure you want to perform this action?',
        alert: 'Warning',
        info: 'Information',
      },
      globalError: "Sorry an error has occurred.<br />The administrator of the site has been notified.<br />Please try again later.",
      globalError401: "You don't have the rights to access this page.",
      hello: 'hello',
      login: 'login',
      logout: 'Logout',
      input: {
        date: {
          error: "Invalid date (Format required: DD/MM/YYYY)"
        }
      },
      btn: {
        show_dedail: 'Show Detail',
        close: 'Close',
        save: 'Save',
        next: 'Next',
        send: 'Send',
        previous: 'Previous',
        search: 'Search',
        resize: {
          minimise: 'Reduce',
          maximise: 'Enlarge',
        },
      },
      user: {
        code: 'Code client : {code}',
        name: 'Name : {name}',
        phone: 'Phone : {phone}',
        email: 'Email : {email}',
        address: 'Address',
        contact: 'Contact : {contact}',
      },
      customers: {
        table: {
          idErp: 'Centre identifier',
          name: 'Center Name',
          address: 'Address',
          phone: 'Phone',
          mg: {
            order_blocked: 'Blocking orders',
          }
        }
      },
      customer: {
        title: "Customer",
        btn: {
          create_return: "Create a return",
          customer_sheet: "Customer sheet",
          customer_purchase: "Customer purchase",
          customer_stock: "Customer stock",
          customer_orders: "Customer orders",
        },
        sheet: {
          title: 'Customer sheet',
          customer_disc_group: 'Group',
          customer_id: 'Customer number',
          customer_name: 'Name',
          address: 'Zip & City',
          phone: 'Phone',
          email: 'Email',
        },
        customer_purchase: {
          no_data: "No data available",
          table: {
            idErpItem: "Item",
            vendorItemNo: "Short ref",
            groupFamily: "Family",
            totalPurchase: "Total purchases",
            quantityRequested: "Web requests",
            quantityStockType: "Stock type",
            averagePurchase: "Monthly average",
          },
        },
        customer_stock: {
          no_data: "No data available",
          btn: {
            error_stock: 'Stock file import errors',
          },
          table: {
            groupFamily: "Family",
            idErpItem: "Article",
            vendorItemNo: "Ref. supplier",
            stockQty: "Stock Total",
            stockAvaiblable: "Stock Available",
            qtyOnPurchaseOrder: "Quantity purchase order",
            qtyOnSalesOrder: "Quantity sales order",
            qtyStockType: "Stock Type Parameterized",
          },
        },
        customer_orders: {
          orderCount: "Orders {year}",
          ca: "CA {year}",
          averageOrder: "Average order {year}",
          table: {
            orderNo: "Order number",
            formatedDateOrder: "Order date",
            orderAmount: "Amount",
            externalRef: "External reference",
          },
          modal_detail_order: {
            title: "Order details :{orderNo}",
            orderNo: "Order No: {orderNo} ({date})",
            externalRef: "External Ref: {externalRef}",
            amount: "Amount: {amount}",
            table: {
              groupingFamily: "Family",
              idErpItem: "Item",
              vendorItemNo: "Supplier ref",
              initialQty: "Ordered quantity",
              qty: "Quantity delivered",
            },
          },
        },
      },
      return: {
        return_validation: {
          message_return_validation_success: "Your return has been validated. Return number: {returnOrderNumber}",
          shipping_sheet: {
            title: "Summary of your return",
            carrier_name: "Carrier",
            qty_package: "Number of packages/pallets",
            contact: "Contact",
            phone: "Telephone",
            btn_validate: "Validate your return",
            btn_set_shipping: "Transport to validate",
          },
        },
        select_shipping: {
          form: {
            title: "Mandatory information to be completed for pick-up",
            accept_terms: "I accept the terms and conditions of the return",
            qtyPackage: "Number of packages to remove",
            qtyPallet: "Number of pallets to remove",
            contact: "Contact",
            phone: "Telephone",
            email: "Email",
            not_null_contact: "Contact is mandatory",
            not_null_phone: "Telephone is mandatory",
            not_valid_phone: "Phone is not valid",
            not_null_email: "Email is mandatory",
            not_valid_email: "Email is invalid",
            not_valid_accept_terms: "You must accept the terms and conditions of the return",
            error_backend: "An error has occurred, please try again later",
          },
          selected_carrier: "Based on the volume and weight of your return, the carrier selected for pickup is:",
          return_weight: "Total weight of your return: {weight}",
          btn_select_carrier: "Select another carrier",
          info_package: {
            tnt: `<h4>Conditioning <b>TNT</b></h4>
              <p>Per individual cardboard package (not exceeding 30 kg)</p>
              <ul >
                <b>Warning:</b>
                <br>
                <li>a transport label provided by CAP PERFORMANCE must be stuck on each package.</li>
                <br>
                <li>a penalty of €30 will be charged for any package over 30 kg. (Transportation Act)</li>
                <br>
                <li>in the event of non-delivery of the goods when the carrier passes, the presentation costs inherent in the passage will be invoiced to the center.</li>
              </ul>`,
            schen: `<h4>Conditioning <b>SCHENKER</b></h4>
            <p>On pallet.</p>
            <ul >
            <b>Warning:</b>
            <br>
            <li>the products must be grouped beforehand in cardboard boxes placed on a pallet.</li>
            <br>
            <li>the pallet must imperatively be filmed.</li>
            <br>
            <li>the CAP PERFORMANCE address must be indicated on the pallet.</li>
            <br>
            <li>in the event of non-delivery of the goods when the carrier passes, the presentation costs inherent in the passage will be invoiced to the center.</li>
            </ul>`,
          }
        },
        header_step_return: {
          step_1: 'Data analysis',
          step_2: 'Item selection',
          step_3: 'Shipment preparation',
          step_4: 'Carrier selection',
          step_5: 'Final validation',
        },
        check_return: {
          alert_not_all_return_item_checked: "You must confirm the number of items and quantities to proceed to the next step.",
          detail_preco: {
            title: "Detail of your return",
            table: {
              groupFamily: "Family",
              idErpItem: "Reference",
              shortRef: "Short reference",
              unitPriceHt: "Purchase price",
              qtySelected: "Quantity returned",
              amount: "Amount"
            },
          },
          family_preco: {
            title: "Details of your return by family",
            table: {
              checked: "Control CDS",
              regroupementFamily: "Family",
              countIdErpItem: "Number of references",
              qty: "Quantities",
              amount: "Amount",
            },
          },
          general_packing_instruction: `<h4>General packing instructions</h4>
                                          <p>
                                            Use one or more rigid and in good condition boxes.
                                            <br /><br />
                                            IMPORTANT: to avoid damaging the products, never mix product families together. Use one box per product family (box containing only braking products and/or box containing only filtration products or other lightweight product).
                                            <br /><br />
                                            Perfectly cushion your products together using cushioning paper or padding materials that you have compacted to prevent any movement inside your boxes.
                                            <br /><br />
                                            To comply with transport regulations, your boxes must not exceed a weight of 30 kg (warning: a surcharge of €30 per package over 30 kg will be applied in case of excess).
                                            <br /><br />
                                            Place the delivery note, detailing your products (reference and quantity), inside your package or on your package in a transparent pocket provided for this purpose.
                                            <br /><br />
                                            Close your package using a solid adhesive tape roll intended for shipping.
                                          </p>
                                          <h4>For individual package shipments (via TNT)</h4>
                                          <p>
                                            Stick a shipping label per package (label provided by CAP PERFORMANCE).
                                            <br /><br />
                                            Each label has a unique barcode.
                                            <br /><br />
                                            For a shipment containing several packages, you will receive as many labels as announced packages.
                                            <br /><br />
                                            The package number will be specified on the label as follows, example for 3 packages:
                                          </p>
                                          <ul>
                                            <li>Package <b>1/3</b></li>
                                            <li>Package <b>2/3</b></li>
                                            <li>Package <b>3/3</b></li>
                                          </ul>
                                          <h4>For pallet shipments (via SCHENKER)</h4>
                                          <p>
                                            Gather the boxes on the pallet, taking care to place the heaviest boxes at the bottom, up to the lightest.
                                            <br /><br />
                                            Make full use of the pallet surface for each layer of products, without ever exceeding the pallet boxes (risk of degradation).
                                            <br /><br />
                                            Film the pallet(s).
                                            <br /><br />
                                            Clearly indicate the CAP PERFORMANCE address on each pallet of your shipment.
                                            <br /><br />
                                            Attention: On the carrier's consignment note, the shipping unit is <u>the number of pallets shipped</u> (and not the number of boxes contained on your pallets).
                                          </p>`
        },
        select_return: {
          error: {
            no_preco: "No return recommendations are available for this center.",
          },
          alert_no_return_item_selected: "You must select at least one item to proceed to the next step.",
          modal_return_item_by_family: {
            title: "List of ERM stock items",
            family: "Family : ",
            table: {
              vendor: "Supplier",
              idErpItem: "Reference",
              shortRef: "Short reference",
              designation: "Designation",
              qtyStockCustomer: "Stock center to date",
              isReturnable: "Return possible",
              qtyStockReservedCustomer: "Stock reserved",
              qtyStockType: "Stock type set",
              historicalQuantityBuyToCap: "CAP purchase history",
              qtyPossibleToReturn: "Maximum quantity allowed in return",
            },
          },
          modal_find_item: {
            title: 'Information about an article',
            id_erp_item: 'Reference',
            btn: 'Search',
            id_erp_item_required: "Reference is required",
            id_erp_item_not_found: "No items found with this reference",
            excluded: "Item excluded from merchandise returns",
            table: {
              vendor: "Supplier",
              groupement_family: "Family",
              ref: "Reference",
              description: "Description",
              qty_stock: "Stock center to date",
              qty_reserved: "Stock reserved",
              qty_type: "Stock type parameterized",
              is_returnable: "Return possible",
              historique_purchase: "CAP purchase history",
              qty_returnable: "Maximum quantity allowed in return",
            }
          },
          modal_return_item_error: {
            title: 'Stock file import errors',
            table: {
              idErpItem: 'Supplier reference',
              brandItemRef: 'Manufacturer reference',
              customerItemRef: 'National reference',
              codeEan: 'EAN code',
              customerStockQty: 'Quantity in stock',
              error: 'Type of error',
              errorEan: 'EAN code in error',
              errorRef: 'No known reference',
            }
          },
          modal_select_carrier: {
            title: "Carrier selection",
          },
          modal_return_file: {
            title: "Stock return inventory sheet",
            type_choice: "Choose the selection for your stock return inventory sheet",
            format_choice: "Choose the format of your stock return inventory sheet",
            email: "Receiving email address",
            not_null_email: "Email address cannot be empty",
            format_pdf: "PDF",
            format_excel: "Excel",
            send: "Send",
            print: "Print",
            error_global: "An error occurred while generating your stock return inventory sheet",
            type: {
              all: "Complete list",
              preco: "Recommended list",
              selected: "Selected list",
            },
          },
          modal_stats_detail_return_item: {
            title: 'History article',
            family: 'FAMILY : ',
            ref: 'ARTICLE REFERENCE : ',
            short_ref: 'SHORT REFERENCE : ',
            histo_purchase: 'Purchasing history and stock queries at CAP PERFORMANCE',
            histo_sales: 'History of checkouts from the center to the reference and to competing equivalent references',
            integration_sales_date: 'Date of last checkouts communicated to CAP: ',
            table: {
              purchase: 'CAP purchases',
              etai: 'Available',
              total: 'TOTAL',
              sales_item: 'Sales per id',
              sales_substitute: 'Sales id equivalent',
            },
          },
          modal_authorized_amount: {
            title: 'Authorized return amount',
            max_return_authorized: 'The authorized return amount for the center is : {amount}',
            balance_authorized: 'Balance: {amount}',
          },
          btn: {
            authorized_amount: 'View authorized return amount',
            print: 'Print your return',
            email: 'Send your return by email',
            search_item: 'Information about an item',
            error_stock: 'Stock file import errors ({count})',
            init_return: 'Initialize your return',
            init_is_selected: 'Modify your return',
            next_step: 'Next',
          },
          amount: 'Amount of selected items: {amount}',
          qty_selected_item: 'Total references selected: {qty}',
          qty_selected: 'Total selected quantities: {qty}',
          family_preco: {
            item_available: "Eligible references: {count}",
            item_preco: "Recommended references :",
            item_selected: "Selected references : {count}",
            amount: "Amount: {amount}",
            table: {
              list_stock: "List of references in stock",
              alert: {
                selected_reserved: "You will return potentially reserved parts.",
                overt_amount: "You do not have a sufficient balance to add this part to your return",
                can_not_return: "You cannot exceed the quantity authorized for this part",
                title_unhautorized: 'Action not allowed',
                selected_stock_type: `This item is a stock type.<br><br>
                You must have a minimum quantity of {count} in stock.
                `,
              },
              hidden_line_modified: "Hide processed lines",
              view_all_line: "View All Lines",
              detail: "View Detail",
              idErpItem: "Article",
              shortRef: "Short reference",
              stock_customer: "Stock center",
              stock_reserved: "Stock reserved",
              stock_type: "Stock type parameterized",
              purchase: "CAP purchase history",
              last_purchase_date: "Last order date",
              unit_price: "Unit price",
              preco: "Recommended item",
              max_available_qty: "Max. quantity allowed",
              qty_selected: "Quantity returned",
              amount: "Amount",
              item_no_returnable: "Quantity not returnable (HS packaging)",
              check: "Processing",
            },
          }
        },
        ask_refresh_return: `<p>Attention,<br><br>
                A manual type return is being generated.
                <br>Creating your new return will result in its deletion.
                <br><br>Would you like to continue creating your new manual return?
            </p>`,
        btn: {
          calc_return: 'Calculate return',
          reset_return: 'Reset return',
        },
        error: {
          no_stock: "No stock is available for this center.",
        },
        step1: {
          title: 'Preparing the return',
          desc: "Return preparation according to your sign.",
        },
        step2: {
          title: 'Stock analysis',
          desc: "Analysis of your stocks and typical stocks as of the day in order to optimize our return recommendations.",
        },
        step3: {
          title: 'Purchasing analysis',
          desc: "Analysis of purchases made at CAP Performance on items currently in stock at your center.",
        },
        step4: {
          title: 'Sales analysis',
          desc: "Analysis of your sales over the last 12 months to identify your slow-moving items.",
        },
        step5: {
          title: 'Analysis of Atelio queries',
          desc: "Analysis of your Atelio stock queries over the last 12 months on items currently in stock in your center.",
        },
        step6: {
          title: 'Creation of your return',
          desc: "Data compilation and recommendation calculation",
        },
      },
      customer_groups: {
        table: {
          idErp: 'ERP sign code',
          label: 'Name of the brand',
          logo: 'Logo',
          blocked: 'Status'
        }
      },
      modalUpdateCustomerGroup: {
        title: "Updating the brang",
        btn: "Send",
        btnDelete: "Delete brand",
        btnCreate: "Create a brand",
        error: {
          create: "To create a sign you need at least an ERP identifier and a label",
          update: "To modify a sign you need at least one field filled in",
        },
        form: {
          idErp: 'ID',
          label: 'Name of the brand',
          blocked: 'Block?',
          validator: {
            idErp: {
              notNull: "The brand identifier must not be empty.",
            },
            libelle: {
              notNull: "The name of the sign must not be empty.",
            },
          },
        }
      },
      search: {
        searchGlobal: 'Search',
        title: 'Search form',
        reset: 'Reset search',
        pages: "{nb} items per page",
        searchCustomer: {
          selectCustomerGroup: "Brand",
          postCode: 'Department',
          customerId: 'Center number',
          error: {
            emptySearch: 'One search field must be filled at least',
            noAccess: 'You do not have access to this customer',
          }
        },
        error: {
          too_many_results: 'Too many results',
          no_result: 'No result',
          global: 'An error has occurred, please try again later',
          empty: 'The search field must be filled',
        },
      },
      create_user: {
        form: {
          fileCsv: "CSV File",
          upload: "Import users",
        },
        table: {
          username: "Nickname",
          firstName: "First name",
          lastName: "Name",
          email: "Email",
          erpId: "ERP No°",
          isManager: "Center Manager?",
          idKeycloak: "SSO No°",
          createdInKeycloak: "Added SSO?",
          createdInMyGarage: "Added My Garage?",
        },
        error: {
          missingCsv: "Please select a user import CSV file"
        },
        report: {
          csvUsers: "Number of users present in the CSV: {qty}",
          csvManagerMyGarage: "Number of Manager users present in the CSV: {qty}",
          csvUsersImportedKeycloak: "Number of users imported into keycloak: {qty}",
          csvUsersImportedMyGarage: "Number of users imported into My Garage: {qty}",
        },
      },
      router: {
        title: {
          home: '<i class="bi bi-house-fill"></i>',
          admin: 'Administration',
          customers: 'Customers',
          customer: 'Customer',
          create_return_order: 'Create a return',
          select_return_order: "Select your items to return",
          gestion_customer_group: 'Brand management',
          other_univers: 'Other universes',
          extern_equipement_atelier: 'Workshop Equipment',
          extern_my_garage: 'My Garage',
          create_user: "Create users",
          my_garage: "My Garage Administration",
          excluded_item: "Manage Item Integration Exclusions",
          gestion_customer_group_mg: "Management of My Garage brands",
          gestion_users_mg: "Management of My Garage Users",
          gestion_rac: "Car center management",
          amazon_orders: "Amazon orders",
          amazon_orders_detail: "Amazon orders detail",
          amazon_shipping_stats: "TNT Carrier Performance on BOSCH Amazon DropShip Shipments",
          amazon_manage_shipping_remainder: "Management of delivery Remainders",
          gestion_force_update_item: "Forced import of ERP items into My Garage",
          check_return_order: "The essentials for shipping your return",
          select_shipping: "Organization of merchandise collection",
          return_validation: "Validation of your return",
          customer_purchase: "Customer purchases",
          customer_stock: 'Customer stock',
          customer_orders: 'Customer orders',
        }
      },
      contact: {
        success: 'Your message has been sent',
        title: 'Contact',
        contactUs: 'Contact us',
        form: {
          globalError: 'You need to fill all the fields correctly',
          subject: {
            label: 'Subject',
            validator: {
              notNull: 'Please enter a subject',
            }
          },
          customSubject: {
            label: 'Custom subject',
            validator: {
              notNull: 'Please enter a custom subject',
              length: 'The custom subject must be at least 10 characters',
            }
          },
          name: {
            label: 'Name',
            validator: {
              notNull: 'Please enter a name',
              length: 'Your first name must contain less than 100 characters',
            }
          },
          firstName: {
            label: 'First name',
            validator: {
              notNull: 'Please enter a first name',
              length: 'Your first name must contain less than 100 characters',
            }
          },
          email: {
            label: 'Email',
            validator: {
              notNull: 'Please enter an email',
              regex: 'Please enter a valid email',
            }
          },
          tel: {
            label: 'Phone',
            validator: {
              notNull: 'Please enter a phone number',
              regex: 'Please enter a valid phone number',
            }
          },
          textRequest: {
            label: 'Request  ( min 10 characters )',
            validator: {
              notNull: 'Please enter a request',
              length: 'The request must be at least 10 characters',
            }
          }
        }
      },
      modal_create_update_excluded: {
        form: {
          code: "Exclusion code",
          validator: {
            coded: {
              not_null: "the exclusion code cannot be empty"
            }
          }
        },
        title: 'Exclude',
        btn_create: "Adding an exclusion",
        btn_save: "Envoyer",
      },
      excluded_item: {
        itc: 'Family',
        pgc: 'Sub Family',
        rf: 'Grouping family',
      },
      table_excluded_global: {
        id: 'ID',
        code: 'Code',
        delete: 'Delete'
      },
      gestion_customer_group_mg: {
        btn_create: "Create customer group",
        table: {
          idErp: 'ERP customer group ID',
          libelle: 'Customer group name',
          logo: 'Logo',
          accessExternalDocumentNo: "Access to internal reference option",
          limitOrderPriceNeedValidation: "Validation required for orders above threshold",
          limitPriceItemCanBuy: "Maximum purchase price per item",
          no_limit: "No limit",
        },
        modal: {
          title: "Customer group",
          save_btn: "Save",
          btn_delete: "Delete customer group",
          form: {
            idErp: 'ID',
            libelle: 'Name',
            accessExternalDocumentNo: "Access to internal reference option",
            limitOrderPriceNeedValidation: "Validation required for orders above threshold",
            limitPriceItemCanBuy: "Maximum purchase price per item",
            validator: {
              libelle: {
                notNull: "The customer group name must not be empty.",
              },
              idErp: {
                notNull: "The customer group ID must not be empty.",
              },
            }
          }
        },
        family_excluded: {
          btn_open: "Open list of excluded families",
          btn_create: "Add excluded family",
          modal: {
            title: "Management of excluded families",
            btn_delete: "Delete excluded family",
            add_title: "Add excluded family",
            add_select: "Select excluded family",
            add_send: "Add excluded family",
            add_value: "Family: {libelle} {fullLibelle}",
          },
          table: {
            libelle: "Label",
            fullLibelle: "Family position tree",
            delete: "Delete"
          }
        },
        item_type_erp_excluded: {
          btn_create: "Add ERP Family Excluded",
          btn_open: "Open the list of excluded ERP families",
          modal: {
            title: "Excluded Family from ERP",
            btn_delete: "Delete",
            add_title: "Add ERP Family Excluded",
            add_select: "Select ERP Family",
            add_value: "Family: { libelle }, Family Code: { itemCategoryCode }, Sub-Family Code: { code }"
          },
          table: {
            caption: "Name",
            itemCategoryCode: "Family Code",
            code: "Subfamily code",
            delete: "Delete",
          }
        }
      },
      users_mg: {
        table: {
          username: "Username",
          firstName: "First name",
          lastName: "Last name",
          email: "Email address",
          idRefAutoCenterERP: "Auto center ID",
          nameRefAutoCenter: "Auto center name",
          customerGroup: "Customer group",
          dateLastLogin: "Last login date",
          accessLog: "User Logs",
        },
        logs: {
          btn_open: "Ouvrir les journaux",
          modal: {
            title: "Journaux utilisateur",
          },
          logs: {
            btn_open: "Open logs",
            modal: {
              title: "User Logs",
            },
            table: {
              date: "Date",
              libelle: "Label",
              detail: "Details",
            },
            detail: {
              immat: "Immat:",
              vehicleVariations: "Vehicle Variations:",
              isOutOfCatalog: "Out of Catalog",
              searchText: "Search Text:",
              idRefTypeItem: "Item Type:",
              idRefFamilyItem: "Item Family:",
              idRefTypeRepair: "Repair Type:",
              totalPriceOrderItems: "Total Price :",
              externalDocumentNo: "Order Reference:",
              erpOrderIds: "ERP Order ID:",
            }
          }
        },
      },
      amazon: {
        order_batch: {
          alert_final_check: {
            title: "Final Integration Check",
            content: "AMAZON order: {webOrderId} found in {nbIdenticalOrder} copy in erp: {erpOrderNo}",
            btn: 'Run final check',
          },
          error_import: "An error occurred while integrating the {amazonId} command. Please refresh the page.",
          alert_processing: "Batch being processed by {username}",
          delete: "BATCH EMPTY",
          comfirm_delete: "Are you sure you want to delete batch #{id}",
          order_duplicate: "Duplicate Orders ({count})",
          shipping_stats: "CAP and Transport Performance Statistics",
          number: "Batch No. {idOrderBatch}",
          import_info: "Import on {date} by {name}",
          import_erp: "Import orders into the ERP",
          import_file_csv: "Import new file",
          import_in_progess: "Import in progress, do not leave the page",
          import_btn: "Import",
          id: 'N° BATCH',
          name: 'User',
          name_user_site: "Username",
          date_import: "Import Date",
          date_integration_start: "ERP integration date",
          date_integration_end: "Integration end date",
          date_export_package_shipped: "Export date packages shipped",
          date_export_package_current_delivery: "Export package current delivery date",
          date_export_package_tnt_status: 'Date export status TNT',
          status: 'Statut',
          duplicate: 'Duplicate orders',
          search: {
            error_global: "Dates must be filled in and valid."
          },
          error: {
            missing_csv: 'No file to import'
          },
          export: {
            libelle: "Export",
            date: "(Last file generated on {date})",
            confirm: {
              shipped: `
              <div class="exportAlert">
                <span class="title">ALERT FOR SHIPPED PACKAGES FILE</span>
                <u>
                  <span class="WARNING">MUST be generated on the day of package shipment (in J)</span>
                </u> 
                <p>
                  <ul>
                    <li>
                      <b>It now includes ALL packages shipped during the day</b> = all orders integrated into NAV on the day, across all batches of the day.
                    </li>
                    <li>
                      If you DO NOT generate it <b>exceptionally</b> one day, you will have to contact IT CAP to get it.
                    </li> 
                    <li>
                      Please save the file with the <b>new</b> name when you open it = <b>EXPES_AMAZON_BOSCH_jjmmaaaa.csv</b> (with jjmmaaaa = date of order shipment = date of file generation)
                    </li>
                  </ul>
                </p>
              </div> `,
              progress_delivery: `
              <div class="exportAlert">
                <span class="title">ALERT FOR PACKAGES IN PROGRESS OF DELIVERY FILE</span>
                <p>
                  <ul>
                    <li>
                      <b>It now includes ALL packages shipped the day before</b> = all orders integrated into NAV the day before, across all batches of the day before.</li> <li>If you DO NOT generate it <b>exceptionally</b> one day, you will have to contact IT CAP to get it. </li> <li>Please save the file with the <b>new</b> name when you open it = <b>EN_COURS_LIVR_AMAZON_BOSCH_jjmmaaaa.csv</b> (with jjmmaaaa = date of order shipment = date of the previous "working" day)
                    </li>
                  </ul>
                </p>
              </div>
              `,
              tnt_status: `
              <div class="exportAlert">
                <span class="title">FILE OF STATUS</span>
                <p>
                  <ul>
                    <li>
                      MUST be generated DAILY at the end of the day (if EXCEPTIONALLY you did not generate it, contact IT)
                    </li>
                    <li>
                      <span class="WARNING">Delete the last 3 columns of the file</span> (added to facilitate your follow-up) <span class="WARNING">before integrating it into VENDOR CENTRAL</span>
                    </li>
                    <li>
                      <b>From the next morning, contact the carrier concerned to unblock all packages that have not reached a DEFINITIVE status in this STATUS file.</b>
                    </li>
                  </ul>
                </p>
              </div> `,
              tnt_rest_status: `
                <div class="exportAlert">
                  <span class="title">REMAINDER file</span>
                  <p>
                    <ul>
                      <li>
                        <b>During the past day, you have contacted the carrier concerned and worked to unblock as many REMAINDERS as possible.</b>
                      </li>
                      <li>
                        <b>The only REMAINDERS in this file are those that were impossible to unblock today.</b>
                      </li>
                      <li>
                        It is IMPERATIVE to generate this file DAILY at the end of the day (if EXCEPTIONALLY you have not done so, contact IT).
                      </li>
                      <li>
                        <span class="WARNING">Please remove the last 3 columns from the file</span> (added to facilitate your tracking) <span color="WARNING">before integrating it into VENDOR CENTRAL.</span>
                      </li>
                    </ul>
                  </p>
                </div>`,
            },
          }
        },
        manage_shipping_status_remainder: {
          table: {
            type: "Command type",
            shipping_date: "Shipping date",
            amazon_order_id: "Order id",
            carrier: "Carrier",
            full_packing_no: "package number",
            new_status: "Status",
            new_date_status: "status date",

          },
          btn: {
            save: "Enregistrer les modifications",
          },
          input: {
            select_new_status: {
              placeholder: "choose a status",
            }


          }

        },
        order_batch_detail: {
          date_order: "Order date: {date}",
          date_req_ship: "Required ship date: {date}",
          phone: "Phone number: {phone}",
          sku: "SKU",
          stock: "Stock",
          price: "Price",
          erp_order_no: "Order No: {erpOrderNo}",
          erp_integrated_date: "ERP integration date: {date}",
          error: {
            amazon_date: "<span class='label'>ORDER DATE ERROR: </span>Check that this is not a canceled order.",
            address: "<span class='label'>ADDRESS ERROR: </span>Invalid address",
            phone: "<span class='label'>PHONE ERROR: </span>Invalid phone number.",
            stock: "<span class='label'>STOCK ERROR: </span>Order automatically deleted."
          },
          table: {
            amazon_id: "Data Order",
            date_req_ship: "Request Shipping Date",
            receiver: "Receiver",
            order_items: "Order items",
            status: "Order status",
            info: "Infos"
          }
        },
        order: {
          update: {
            btn_open: "Update Order",
            modal: {
              btn_save: "Update Order",
              title: "Update Order",
              form: {
                name: "Name",
                addr1: "Address 1",
                addr2: "Address 2",
                addr3: "Address 3",
                zip_code: "Zip Code",
                city: "City",
                phone: "Phone",
                country: "Country",
                region: 'State / Department',
                validator: {
                  name: {
                    maxLen: "Name cannot exceed {maxLen} characters. {len}/{maxLen}"
                  },
                  addr1: {
                    maxLen: "Address 1 cannot exceed {maxLen} characters. {len}/{maxLen}"
                  },
                  addr2: {
                    maxLen: "Address 2 cannot exceed {maxLen} characters. {len}/{maxLen}"
                  },
                  addr3: {
                    maxLen: "Address 3 cannot exceed {maxLen} characters. {len}/{maxLen}"
                  },
                  zip_code: {
                    error_global: "Zip Code cannot exceed {maxLen} characters and must be located in metropolitan France or Monaco. {len}/{maxLen}"
                  },
                  city: {
                    maxLen: "City cannot exceed {maxLen} characters. {len}/{maxLen}"
                  },
                  phone: {
                    not_valide_phone: "The phone number must be in French format."
                  },
                  country: {
                    not_fr: "Country code must be FR"
                  },
                  region: {
                    not_null: "Field must always be empty"
                  }
                }
              }
            }
          }
        },
        shipping_stats: {
          btn_generate_file: "Download Amazon Shipping Stats",
          Caption: {
            title: "Caption:",
            ok: "Commitments respected",
            ko: "Unfulfilled commitments",
            sub: "Subtotals",
          },
          table: {
            order_shipped: "Orders Shipped from {start_date} to {end_date} inclusive",
            quantity: "quantity",
            total_orders: "Total number of orders shipped during the analyzed period",
            presentation_rate: "RECIPIENT PRESENTATION RATE at J+1",
            reminder: "REMINDER",
            cap_commitments: "CAP commitment = shipment at J+1 ... from Monday to Friday",
            carrier_commitments: "Carrier commitment = delivery at J+1 from Monday to Friday",
            stats_header: "STATS OBTAINED WITH CURRENT VENDOR CENTRAL STATUTES",
            statement_quantity: "statement quantity",
            statement_percentage: "% statement quantity",
            delivered_quantity: "RECIPIENT PRESENTATION quantity at J+1",
            delivered_percentage: "% RECIPIENT PRESENTATION quantity at J+1 (vs statement quantity)",
            j1: "J+1",
            j2: "J+2",
            j3: "J+3 and beyond",
            delivered_to: "Number of orders Delivered to ",
            delivered_to_j1: "Number of orders Delivered to J+1 ",
            refused_by_recipient: "Nb of orders REFUSED by the recipient ",
            unfeasible_quantity: `Nb of UNDELIVERABLE orders at :`,
            lost_quantity: `Nb of LOST orders at :`,
            delayed_quantity: `Nb of DELAYED orders (recipient or carrier or force majeure causes) at :`,
            delivering_quantity: `Nb of orders IN DELIVERY PROCESS at :`,
            total_engagement: `TOTAL ORDERS DELIVERED OR REFUSED :`,
            total_non_engagement: `TOTAL OTHER ORDERS :`,
            total: `TOTAL`,
            type_order: `Type of orders below on the opposite date :`,
            refused_by_customer: `Nb of orders REFUSED by the recipient at :`,
            total_final: "Total number of orders processed"
          },
        },
      },
      my_garage: {
        force_update_item: {
          btn_update: "Force update",
          count_updated: "Item Count: {count}",
          error_global: "Dates must be filled in and valid.",
          no_result: "No item updated",
          table: {
            idErp: 'ERP identifier',
            caption: 'Caption',
            brand: 'Brand'
          }
        }
      }
    }
  }
}

// 2. Create i18n instance with options
export const i18n = createI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
  numberFormats, // formatage des nombre
  datetimeFormats, // formatage des dates
  // If you need to specify other options, you can set other options
  // ...
})