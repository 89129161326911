
import { useReturnStore } from "@/store/returnStore";
import { computed, defineComponent, ref } from "vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import { ModalBtnType } from "@/type/Modal/ModalType";
import ApiGestionCap from "@/services/ApiGestionCap";
import { useI18n } from "vue-i18n";
import ServiceFormValidation from "@/services/ServiceFormValidation";
import { ModelResponseStatus, ReturnItemError } from "@/type/Return/ReturnType";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import InputTextSite from "@/components/customInput/InputTextSite.vue";

export default defineComponent({
  name: "ModalReturnItemError",
  components: {
    TemplateModaleSite,
    ButtonSite,
    InputTextSite,
  },
  props: {},
  setup() {
    const { t } = useI18n();
    const returnStore = useReturnStore();

    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    const isOpen = ref(false as boolean);
    const saveButton = ref({
      label: "",
      affBtn: false,
    } as ModalBtnType);

    const isPending = ref(false as boolean);

    const rows = ref([] as ReturnItemError[]);

    const filter = ref({
      idErpItem: "" as string,
      customerItemRef: "" as string,
      brandItemRef: "" as string,
      codeEan: "" as string,
      customerStockQty: "" as string,
      errorEan: "" as string,
    });

    const columns = [
      {
        name: "customerItemRef",
        label:
          "message.return.select_return.modal_return_item_error.table.customerItemRef",
        align: "left",
        field: (row: ReturnItemError) => row.customerItemRef,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "idErpItem",
        label:
          "message.return.select_return.modal_return_item_error.table.idErpItem",
        align: "left",
        field: (row: ReturnItemError) => row.idErpItem,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "brandItemRef",
        label:
          "message.return.select_return.modal_return_item_error.table.brandItemRef",
        align: "left",
        field: (row: ReturnItemError) => row.brandItemRef,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "codeEan",
        label:
          "message.return.select_return.modal_return_item_error.table.codeEan",
        align: "left",
        field: (row: ReturnItemError) => row.codeEan,
        sortable: true,
      },
      {
        name: "customerStockQty",
        label:
          "message.return.select_return.modal_return_item_error.table.customerStockQty",
        align: "center",
        field: (row: ReturnItemError) => row.customerStockQty,
        sortable: true,
      },
      {
        name: "errorEan",
        label:
          "message.return.select_return.modal_return_item_error.table.error",
        align: "center",
        field: (row: ReturnItemError) =>
          row.errorEan
            ? t(
                "message.return.select_return.modal_return_item_error.table.errorEan"
              )
            : t(
                "message.return.select_return.modal_return_item_error.table.errorRef"
              ),
        sortable: true,
      },
    ];

    return {
      isOpen,
      saveButton,
      getCustomer,
      ServiceFormValidation,
      isPending,
      rows,
      columns,
      filter,
      t,
    };
  },

  methods: {
    /**
     * fonction qui recupere les famille disponible pour l'exclusion et qui ouvre la modal
     */
    modalOpen() {
      this.filter.idErpItem = "";
      this.filter.customerItemRef = "";
      this.filter.brandItemRef = "";
      this.filter.codeEan = "";
      this.filter.customerStockQty = "";

      this.rows = [];
      this.getReturnItemError();
      this.isOpen = true;
    },
    /**
     * fonction qui ferme la modal
     */
    modalClose() {
      this.isOpen = false;
    },
    /**
     * fonction qui permet de récupérer les retuern item error
     */
    async getReturnItemError() {
      if (this.getCustomer) {
        this.isPending = true;
        try {
          this.rows = await ApiGestionCap.getReturnItemsError(
            this.getCustomer?.id
          );
        } catch (error) {
          router.push({ name: RouteName.Error });
        }

        this.isPending = false;
      }
    },
    filterData(rows: any[], filters: any) {
      for (const [key, value] of Object.entries(filters)) {
        //filtre sur les colonnes avec le filtre correspondant à la clef voulu
        //test du type du filtre (string ou boolean ou null)
        if (typeof value === "string") {
          if (key === "errorEan") {
            const errorEan = this.t(
              "message.return.select_return.modal_return_item_error.table.errorEan"
            );
            const errorRef = this.t(
              "message.return.select_return.modal_return_item_error.table.errorRef"
            );
            if (value === errorEan) {
              rows = rows.filter((row) => row[key] === true);
            } else if (value === errorRef) {
              rows = rows.filter((row) => row[key] === false);
            }
          } else {
            const filterValue = filters[key].toLowerCase().replace(/\s/g, "");
            rows = rows.filter(
              (row) =>
                (row[key] + "")
                  .toLowerCase()
                  .replace(/\s/g, "")
                  .indexOf(filterValue) !== -1
            );
          }
        }
      }
      return rows;
    },
  },
});
