
import { isTokenReady } from "@baloise/vue-keycloak";
import { computed, defineComponent, ref } from "vue";
import HeaderCustomerData from "@/components/customers/HeaderCustomerData.vue";
import HeaderStepButton from "@/components/return/HeaderStepButton.vue";
import { useReturnStore } from "@/store/returnStore";
import { RouteName } from "@/constante/RouterConst";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import { usePendingStore } from "@/store/pendingStore";
import { useI18n } from "vue-i18n";
import ApiGestionCap from "@/services/ApiGestionCap";
import { ModelSelectShipping, RefCarrier } from "@/type/Return/ReturnType";
import ModalSelectCarrier from "@/components/return/ModalSelectCarrier.vue";
import InputNumberSite from "@/components/customInput/InputNumberSite.vue";
import InputTextSite from "@/components/customInput/InputTextSite.vue";
import ServiceFormValidation from "@/services/ServiceFormValidation";
import QForm from 'quasar/src/components/form/QForm.js';;
import dialog from "@/services/DialogSite";
import { router } from "@/router";

export default defineComponent({
  name: "SelectShipping",
  components: {
    HeaderCustomerData,
    HeaderStepButton,
    ButtonSite,
    ModalSelectCarrier,
    InputNumberSite,
    InputTextSite,
  },
  setup() {
    const { t } = useI18n();
    const pendingStore = usePendingStore();
    const returnStore = useReturnStore();
    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    const getReturnData = computed(() => {
      return returnStore.getReturnData;
    });

    const getHeaderStepReturn = computed(() => {
      return returnStore.getHeaderStepReturn;
    });

    const idCustomer = ref(null as string | null);

    const getReturnPreco = computed(() => {
      return returnStore.getReturnPreco;
    });

    const getTotalWeightSelected = computed(() => {
      return returnStore.getTotalWeightSelected();
    });

    const refCarriers = ref(null as null | RefCarrier[]);

    const selectedCarrier = ref(null as null | RefCarrier);

    const modelSelectShipping = ref({} as ModelSelectShipping);

    const isPending = ref(false as boolean);

    const disableElement = computed(() => {
      return isPending.value;
    });

    const getTotalQtySelected = computed(() => {
      return returnStore.getTotalQtySelected();
    });

    return {
      getCustomer,
      getReturnData,
      getHeaderStepReturn,
      getReturnPreco,
      getTotalWeightSelected,
      idCustomer,
      returnStore,
      pendingStore,
      refCarriers,
      t,
      selectedCarrier,
      modelSelectShipping,
      disableElement,
      isPending,
      ServiceFormValidation,
      getTotalQtySelected,
    };
  },
  async mounted() {
    await isTokenReady();
    this.pendingStore.$patch({ routerIsPending: true });
    this.idCustomer = this.$route.params.idCustomer as string;
    if (!this.idCustomer) {
      this.$router.push({ name: RouteName.Customers });
    }
    await this.returnStore.initReturn(this.idCustomer);

    this.modelSelectShipping = {
      idErpCustomer: this.idCustomer,
      idReturnData: this.getReturnData?.id ?? 0,
      qtyPackage: 1,
      contact: this.getCustomer?.contact ?? "",
      phone: this.getCustomer?.phoneNo ?? "",
      email: this.getCustomer?.email ?? "",
      idRefCarrier: this.selectedCarrier?.id ?? 0,
      weight: this.getTotalWeightSelected,
    };

    if (!this.getReturnData) {
      this.$router.push({
        name: RouteName.CreateReturnOrder,
        params: { idCustomer: this.idCustomer },
      });
    } else {
      this.modelSelectShipping.idRefCarrier =
        this.getReturnData.idRefCarrier?.id ?? 0;
      this.modelSelectShipping.qtyPackage =
        this.getReturnData.numberOfPackages ?? 1;
      this.modelSelectShipping.contact = this.getReturnData.contactName ?? "";
      this.modelSelectShipping.email = this.getReturnData.contactEmail ?? "";
      this.modelSelectShipping.phone = this.getReturnData.contactTel ?? "";
    }

    if (
      this.getReturnData &&
      this.getReturnData.allStatusStepOk &&
      !this.getReturnPreco
    ) {
      //récupération de la préco dans le store
      await this.returnStore.refreshReturnPreco();
    }

    //récupération des données transporteurs
    this.refCarriers = await ApiGestionCap.getRefCarrier(
      this.getTotalWeightSelected
    );

    this.selectedCarrier = this.refCarriers?.[0];

    this.refCarriers?.forEach((carrier) => {
      if (
        this.getReturnData?.idRefCarrier?.id &&
        carrier.id === this.getReturnData.idRefCarrier.id
      ) {
        this.selectedCarrier = carrier;
      } else if (carrier.isDefault) {
        this.selectedCarrier = carrier;
      }
    });

    this.pendingStore.$patch({ routerIsPending: false });
    this.returnStore.initHeaderStepReturn();
    if(this.getCustomer && this.getTotalQtySelected === 0){
      this.$router.push({
        name: RouteName.SelectReturnItem,
        params: { idCustomer: this.getCustomer.id },
      });
    }
  },
  methods: {
    async nextStep() {
      if (this.getCustomer) {
        await this.validCarrier();
      }
    },
    setCarrier(idRefCarrier: number) {
      this.refCarriers?.forEach((carrier) => {
        if (carrier.id === idRefCarrier) {
          this.selectedCarrier = carrier;
          this.modelSelectShipping.idRefCarrier = carrier.id;
        }
      });
    },
    openModalSelectCarrier() {
      const ModalSelectCarrier = this.$refs.ModalSelectCarrier as any;
      if (ModalSelectCarrier && this.selectedCarrier) {
        ModalSelectCarrier.setCarrier(this.selectedCarrier.id);
        ModalSelectCarrier.modalOpen();
      }
    },
    updateQtyPackage(newQty: number) {
      this.modelSelectShipping.qtyPackage = newQty;
    },
    async validCarrier() {
      const formValidCarrier = this.$refs.formValidCarrier as QForm;
      if (await formValidCarrier.validate()) {
        if (this.selectedCarrier) {
          this.isPending = true;
          this.pendingStore.$patch({ routerIsPending: true });
          try {
            this.modelSelectShipping.idRefCarrier = this.selectedCarrier.id;
            const success = await ApiGestionCap.setShippingData(
              this.modelSelectShipping
            );
            await this.returnStore.refreshReturnData();
            if (success) {
              this.pendingStore.$patch({ routerIsPending: false });
              this.$router.push({
                name: RouteName.ReturnValidation,
                params: { idCustomer: this.idCustomer },
              });
            } else {
              dialog.alertReturn(
                this.t("message.return.select_shipping.form.error_backend")
              );
            }
          } catch (error) {
            this.pendingStore.$patch({ routerIsPending: false });
            router.push({ name: RouteName.Error });
          }

          this.isPending = false;
        }
      }
    },
    getLabelInputNumberCarrier() {
      if (this.selectedCarrier) {
        if (this.selectedCarrier.havePallet) {
          return this.t("message.return.select_shipping.form.qtyPallet");
        } else {
          return this.t("message.return.select_shipping.form.qtyPackage");
        }
      }
    },
  },
});
