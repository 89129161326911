
import { useReturnStore } from "@/store/returnStore";
import { computed, defineComponent, ref } from "vue";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import { RouteName } from "@/constante/RouterConst";
import ModalAuthorizedAmount from "@/components/return/ModalAuthorizedAmount.vue";
import ModalReturnFile from "@/components/return/ModalReturnFile.vue";
import ModalReturnItemError from "@/components/return/ModalReturnItemError.vue";
import ModalFindItem from "@/components/return/ModalFindItem.vue";
import { usePendingStore } from "@/store/pendingStore";
import ApiGestionCap from "@/services/ApiGestionCap";

export default defineComponent({
  name: "HeaderSelectReturnItem",
  components: {
    ButtonSite,
    ModalAuthorizedAmount,
    ModalReturnFile,
    ModalReturnItemError,
    ModalFindItem,
  },
  setup() {
    const pendingStore = usePendingStore();
    const returnStore = useReturnStore();
    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    const getReturnData = computed(() => {
      return returnStore.getReturnData;
    });

    const getReturnPreco = computed(() => {
      return returnStore.getReturnPreco;
    });

    const getTotalQtySelected = computed(() => {
      return returnStore.getTotalQtySelected();
    });

    const getTotalReferenceSelected = computed(() => {
      return returnStore.getTotalReferenceSelected();
    });

    const getTotalAmountPreco = computed(() => {
      return returnStore.getTotalAmountPreco();
    });

    return {
      pendingStore,
      returnStore,
      getReturnData,
      getReturnPreco,
      getTotalQtySelected,
      getTotalReferenceSelected,
      getTotalAmountPreco,
      getCustomer,
    };
  },
  methods: {
    async resetReturn() {
      if (this.getCustomer) {
        await this.returnStore.resetReturn();
        //redirection a la Create return
        this.$router.push({
          name: RouteName.CreateReturnOrder,
          params: { idCustomer: this.getCustomer.id },
        });
      }
    },
    async initSelected() {
      this.pendingStore.$patch({ routerIsPending: true });
      if (this.getCustomer && this.getReturnData) {
        const response = await ApiGestionCap.updateReturnItemsSelected(
          this.getReturnData.id,
          this.getCustomer.id,
          0
        );
        if (response.success) {
          await this.returnStore.refreshReturnData();
          await this.returnStore.refreshReturnPreco();
        }
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    openModalAuthorizedAmount() {
      const modalAuthorizedAmount = this.$refs.modalAuthorizedAmount as any;
      if (modalAuthorizedAmount) {
        modalAuthorizedAmount.modalOpen();
      }
    },
    openModalReturnFile(isEmail: boolean) {
      const modalReturnFile = this.$refs.modalReturnFile as any;
      if (modalReturnFile) {
        modalReturnFile.modalOpen(isEmail);
      }
    },
    openModalReturnItemError() {
      const modalReturnItemError = this.$refs.modalReturnItemError as any;
      if (modalReturnItemError) {
        modalReturnItemError.modalOpen();
      }
    },
    openModalFindItem() {
      const modalFindItem = this.$refs.modalFindItem as any;
      if (modalFindItem) {
        modalFindItem.modalOpen();
      }
    },
  },
});
